import React, { Component } from 'react';
import "./step.css";

class Step extends Component {


  renderIcon = (state) => {
    if (state == 'todo') {
      return (
          <div className="todoicon"></div>
        )
    } else {
      return (
          <div className="doneicon"></div>
        )
    }
  }

  render() {
    const classes= `${this.props.step.state} step step-${this.props.step.id}`;
    return (
      <div className={classes}>
        <div className="step-icon"></div>
        <div className="step-title">{this.props.step.name}</div>
        <div className="step-date">{this.props.step.date}</div>
      </div>
    );
  }
}


export default Step;
