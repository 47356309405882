// NB: I tried to make this an ERB file to read ruby ivar & constants
//     but does not seem to work. It would be great to fix this so I can use
//     state constant instead

import NotifList from "../../containers/notif_list/notif_list";
import "./notif_list.css";

const selector = "#js-notif-list__notifs-root";
const reactRootDomEl = document.getElementById(selector.substring(1));
const reactRootDomSelectors = {};

if (reactRootDomEl) {
  reactRootDomSelectors[selector] = {
    component: NotifList
  };
}

export { reactRootDomSelectors };
