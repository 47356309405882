import "./need_modal_steps.css";
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import NeedSteps from '../../containers/need_steps/need_steps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


class NeedModalSteps extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () =>{
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }

  render() {
    return (
      <>
        <FontAwesomeIcon icon={faInfoCircle} onClick={this.open} className="clic" />

        <Modal id="NeedStepsModal" show={this.state.showModal} onHide={this.close} onEntered={this.setDatepicker}>
          <Modal.Header closeButton>
            <Modal.Title>Récapitulatif de la mission</Modal.Title>
          </Modal.Header>
          <Modal.Body closeButton>
            <NeedSteps needId={this.props.needId} />
          </Modal.Body>
        </Modal>
      </>
    );
  }

}

export default NeedModalSteps;
