import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchFirms } from '../../actions/index';

import { Link } from 'react-router-dom';

import "./need_provider_list_view.css";


import DataTable from "../../components/datatable/datatable";

class NeedProviderListView extends Component {

  confirmClick() {
    let x = confirm("Êtes-vous sur ?");
    if (x)
      return true;
    else
      return false;
    }

  render() {
    const needId = this.props.match.params.needId;
    const path = `/caretaker/needs/${needId}/client`

    // NB:  These are not the right final columns but can be updated
    const columns = [
      // { accessor: "id", show: true, disableFilters: true,
      //   Header: ({ getToggleAllRowsSelectedProps }) => (
      //     <div>
      //       <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
      //     </div>
      //   ),
      //   Cell: ({ row }) => (
      //     <div>
      //       <input type="checkbox" defaultValue={row.cells[0].value} name={row.cells[0].column.id}
      //         {...row.getToggleRowSelectedProps()}
      //       />
      //     </div>
      //   )
      // }, // NB; temporary disabled
      { Header: "Prestataire", accessor: "name", disableFilters: true, },
      { Header: "N° de SIREN", accessor: "national_id", disableFilters: true, },
      { Header: "Commission", accessor: "percent_fee", disableFilters: true, },
      {
        Header: '', disableFilters: true, accessor: "select_url",
        Cell: ({ row }) => (
          <a className='datatable__table__link' onClick={() => { this.confirmClick() } } data-method='post' href={row.values.select_url}>Sélectionner</a>
        )
      }
    ];


    // NB: I need to get providers with same attributes key as columns accessor
    const data = this.props.firms;

    return (
      <div className="need-provider-list-view block shadow">
        <div className="need-provider-list-view__title">
          <Link className="need-provider-list-view__prev-arrow" to={path}></Link>
          <h3>Choisissez un prestataire</h3>
        </div>
        <div className="need-provider-list-view__table">
          <DataTable
            columns={columns} data={data} />
        </div>
      </div>
    )
  }

}


function mapStateToProps(state) {
  return {
    firms: state.firms
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchFirms }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NeedProviderListView);
