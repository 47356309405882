import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactHtmlParser from 'react-html-parser';
import "./service_price_modal.css";


class ServicePriceModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () =>{
    this.setState({ showModal: false });
  }

  open = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  render() {
    return (
      <li>
          <a href="" onClick={this.open}><div className="icon prices"></div>Guide des prix</a>

          <Modal id="servicePriceModal" className="service-price-modal" show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>
            <h4 className="modal-title" id="exampleModalLabel">Fourchette de prix
              <div className="subtitle">Les données ci-jointes sont des prix extraits des réalisations de nos partenaires. Chaque mission est unique et les prix peuvent varier d'une demande à l'autre.</div>
            </h4>
            </Modal.Title>
          </Modal.Header>
            <Modal.Body>
                <table>
                  <thead>
                    <tr>
                      <td>Services</td>
                      <td>Prix</td>
                    </tr>
                  </thead>
                  <tbody>{ ReactHtmlParser (this.props.price_range) }</tbody>
                </table>

            </Modal.Body>
          </Modal>
      </li>
    );
  }
};

export default ServicePriceModal;
