import React, { Component } from 'react';
import ChatList from '../../containers/chat_list/chat_list';
import RequalifiedNeed from '../../containers/requalified_need/requalified_need';
import ProposalList from '../../containers/proposal_list/proposal_list';
import "./need_cancelled_view.css";

class NeedCancelledView extends Component {

  render() {
    const needId = this.props.match.params.needId
    return (
      <div className="need-cancelled-view">
        <RequalifiedNeed needId={needId} scope={this.props.scope} />
      </div>
    )
  }

}

export default NeedCancelledView;
