import React, { useState, useRef, useMemo } from "react";
import * as _ from "lodash";
import { ParticipantsModal } from "../participants_modal/participants_modal";
import { ParticipantsTableRow } from "./participants_table_row";
import destroyJSON from "../../ajax/destroyJSON";
import postJSON from "../../ajax/postJSON";
import { first } from "lodash/array";
import { find } from "lodash";

export const ParticipantsTable = (props) => {
  const {
    teamMemberships,
    participationIdPerMembershipId,
    needId,
    currentMembership,
  } = props;
  const [show, setShow] = useState(false);
  const [clickedMembershipId, setClickedMembershipId] = useState(null);
  const selectedUser = useMemo(() => {
    if (clickedMembershipId === null) return null;

    return find(teamMemberships, (m) => m.id === clickedMembershipId);
  });

  const handleClose = () => setShow(false);
  const handleClick = (membershipId, participationId, status) => {
    if (status) {
      setClickedMembershipId(membershipId);
      setShow(true);
    } else {
      destroyParticipation(participationId);
    }
  };

  const validateModal = async (message) => {
    const participation = {
      membership_id: clickedMembershipId,
      instructions: message,
    };

    await postJSON(`/needs/${needId}/participations`, {
      participation,
    })
      .then(() => window.location.reload(false))
      .catch((e) => window.location.reload(true));
  };

  const destroyParticipation = async (participationId) => {
    await destroyJSON(`/needs/${needId}/participations/${participationId}`)
      .then(() => window.location.reload(false))
      .catch((e) => window.location.reload(true));
  };

  const members = teamMemberships.sort((a, b) => {
    return a.id < b.id;
  });
  return (
    <div>
      {members.map((m) => {
        return (
          <ParticipantsTableRow
            teamMember={m}
            key={m.id}
            participantsMembershipIds={participationIdPerMembershipId}
            needId={needId}
            currentMembership={currentMembership}
            handleClick={handleClick}
          />
        );
      })}
      <ParticipantsModal
        show={show}
        user={selectedUser}
        validateModal={validateModal}
        handleClose={handleClose}
      />
    </div>
  );
};
