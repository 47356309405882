import { FETCH_QUOTES, POST_QUOTE } from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_QUOTES: {
      return action.payload;
    }
    case POST_QUOTE: {
      if (state.map(quote => quote.id).includes(action.payload.id)) {
        return state;
      }
      const copiedState = state.slice(0);
      copiedState.push(action.payload);
      return copiedState;
    }
    default:
      return state;
  }
}
