import "./need_card.css";
import React, { Component } from "react";
import NeedActions from "../../components/need_actions/need_actions";
import NeedModalSteps from "../../components/need_modal_steps/need_modal_steps";

class NeedCard extends Component {
  renderSubscription = (subscription) => {
    if (subscription == "subscription") {
      return (
        <div className="subscription">
          <div className="icon-subscription"></div>Abonnement
        </div>
      );
    }
  };

  renderProposals = (state) => {
    if (!["validated", "paid", "cancelled", "refused"].includes(state)) {
      return (
        <div className="proposal-number">
          {this.props.need.proposal_nbr} proposition(s) envoyée(s)
        </div>
      );
    }
  };

  renderSteps = (state, role, progress) => {
    const progress_class = `step-bar progress-${progress}`;
    if (role == "caretaker") {
      if (this.props.need.menu_state == "done") {
        return (
          <div className="steps">
            <div className={progress_class}></div>
            <div className="current-step">
              Client : {this.props.need.client_name}
              <div>Contact : {this.props.need.contact}</div>
              <div className="step-name">
                Mission Réalisée - Note : {this.props.need.rating}{" "}
                <NeedModalSteps needId={this.props.need.id} />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="steps">
            <div className={progress_class}></div>
            <div className="current-step">
              Client : {this.props.need.client_name}
              <div>Contact : {this.props.need.contact}</div>
              <div className="step-name">
                Étape : {this.props.need.next_step}{" "}
                <NeedModalSteps needId={this.props.need.id} />
              </div>
            </div>
          </div>
        );
      }
    } else if (this.props.menu_state == "done") {
      return (
        <div className="steps">
          <div className={progress_class}></div>
          <div className="current-step">
            Prestataire : {this.props.need.provider_names}
            <div>Rate : {this.props.need.rating}</div>
            <div className="step-name">
              Étape : {this.props.need.menu_state}{" "}
              <NeedModalSteps needId={this.props.need.id} />
            </div>
          </div>
        </div>
      );
    } else {
      if (!["paid", "cancelled", "refused", "done"].includes(state)) {
        return (
          <div className="steps">
            <div className={progress_class}></div>
            <div className="current-step">
              Prochaine étape
              <div className="step-name">
                {this.props.need.next_step}{" "}
                <NeedModalSteps needId={this.props.need.id} />
              </div>
            </div>
          </div>
        );
      } else if (state == "refused") {
        return (
          <div className="steps">
            <div className="current-step">
              <div className="step-name refused">
                {this.props.need.i18n_state}{" "}
                <NeedModalSteps needId={this.props.need.id} />
              </div>
            </div>
          </div>
        );
      } else if (state == "paid") {
        if (this.props.need.done == true) {
        } else {
          return (
            <div className="steps">
              <div className={progress_class}></div>
              <div className="current-step">
                <div className="step-name">
                  {this.props.need.i18n_state}{" "}
                  <NeedModalSteps needId={this.props.need.id} />
                </div>
              </div>
            </div>
          );
        }
      } else if (state == "cancelled") {
        return (
          <div className="steps">
            <div className="current-step">
              <div className="step-name refused"></div>
            </div>
          </div>
        );
      }
    }
  };

  render() {
    const cat_classes = `cat-overlay ${this.props.need.category
      .replace(/ /g, "_")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")}`;
    const role = this.props.role;

    return (
      <div className="need-card">
        <div className="service-thumbnail">
          <div className={cat_classes}>{this.props.need.service_name}</div>
          <img src={this.props.need.service_img_link} />
        </div>

        <div className="content">
          <div className="entete">
            <div className="deal-number">N° {this.props.need.id}</div>
            {this.renderSubscription(this.props.need.subscription)}
            {this.renderProposals(this.props.need.state)}
          </div>

          {this.renderSteps(
            this.props.need.state,
            role,
            this.props.need.progress
          )}

          <div className="action">
            <a className="black-button" href={this.props.need.show_link}>
              {this.props.need.main_action}
            </a>
          </div>

          <div className="secondary-actions">
            <NeedActions need={this.props.need} role={role} />
          </div>
        </div>
      </div>
    );
  }
}
export default NeedCard;
