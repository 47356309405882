import React from "react";
import { Modal } from "react-bootstrap";
import { CustomButton } from "../custom_button/custom_button";
import "./delete_need_modal.css";

export const DeleteNeedModal = (props) => {
  const { show, deleteLink, handleClose } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        id="rolesModal"
        className="delete-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <p className="deleteText">
              {" "}
              Êtes-vous certain de vouloir supprimer
            </p>
            <p className="deleteText"> cette demande ?</p>
          </div>
          <div>
            <center>
              <CustomButton
                name="annuler"
                type="button"
                value="Annuler"
                width="100"
                height="48"
                color="#143456"
                backgroundColor="secondary"
                borderRadius="56"
                borderColor="#143456"
                onClick={handleClose}
              />
              <a
                className="modal_delete_link"
                role="button"
                href={deleteLink}
                data-method="put"
              >
                <button className="modal_delete_btn">Supprimer</button>
              </a>
            </center>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
