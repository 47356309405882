import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchNeed, fetchAnswers } from "../../actions/index";
import "./requalified_need.css";

class RequalifiedNeed extends Component {
  componentWillMount() {
    this.props.fetchNeed(this.props.needId);
  }

  renderPriorities = (tag) => {
    return (
      <span key={tag.id} className="tag">
        {tag}
      </span>
    );
  };

  handleClick = () => {
    event.preventDefault();
    if (event.target.classList.contains("less")) {
      event.target.parentElement.previousSibling.style.display = "flex";
      event.target.previousElementSibling.style.display = "none";
      event.target.classList.remove("less");
      event.target.innerHTML = "Résumé de la demande";
    } else {
      event.target.previousElementSibling.style.display = "flex";
      event.target.parentElement.previousSibling.style.display = "none";
      event.target.classList.add("less");
      event.target.innerHTML = "Masquer";
    }
  };

  toggleDescription = (description) => {
    return (
      <div>
        {description}
        <span className="moreellipses"></span>
        <span className="morecontent" style={{ display: "block" }}>
          <span>{this.renderAnswers(this.props.scope)}</span>
          <a href="" className="morelink less" onClick={this.handleClick}>
            Masquer
          </a>
        </span>
      </div>
    );
  };

  renderAnswers = (scope) => {
    if (scope == "provider" || scope == "client") {
      return (
        <ul>
          <h4>Résumé de la demande :</h4>
          {this.props.answers.map((answer, index) => {
            return (
              <li key={index}>
                <p className="bold">Q : {answer.question}</p>
                <span className="answerStyle">R : {answer.answer}</span>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  renderInstructions = (scope) => {
    if (scope == "provider") {
      return (
        <div className="block shadow">
          <h3>Notes du concierge</h3>
          {this.props.need.brief} <br />
          {this.props.need.feedback}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  render() {
    return (
      <div className="requalified-need">
        <div className="block shadow">
          {/*<h3>{this.props.need.service_name}</h3>*/}
          {this.toggleDescription(this.props.need.description)}
        </div>
        {this.renderInstructions(this.props.scope)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    need: state.need,
    answers: state.answers,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchNeed }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RequalifiedNeed);
