import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchMessages, markAsRead } from "../../actions";
import Message from "../../components/message/message";
import MessageForm from "../../containers/message_form/message_form";
import "./message_list.css";

class MessageList extends Component {
  componentWillMount() {
    this.fetchMessages();
  }

  componentDidMount() {
    // this.refresher = setInterval(this.fetchMessages, 5000);
    // SUBSCRIBE TO ACTIONCABLE CHAT
  }

  componentWillReceiveProps(nextProps) {
    // For after switching channels
    // if (this.props.selectedChat != nextProps.selectedChat) {
    //   // SUBSCRIBE TO ACTIONCABLE NEW CHAT
    // }
    if (this.props.chatId !== nextProps.chatId) {
      this.props.markAsRead(this.props.notifs.map((n) => n.id));
    }
  }

  componentDidUpdate() {
    this.list.scrollTop = this.list.scrollHeight;
  }

  fetchMessages = () => {
    this.props.fetchMessages(this.props.chatId);
  };

  render() {
    const { current_membership } = this.props;
    if (this.props.messages.length > 0) {
      return (
        <div className="message-list block shadow">
          <div
            className="message-list__content"
            ref={(list) => {
              this.list = list;
            }}
          >
            {this.props.messages.map((message) => {
              const is_sender =
                `${message.user_id}` === `${this.props.userToken}`;
              return (
                <Message
                  key={message.id}
                  message={message}
                  is_sender={is_sender}
                />
              );
            })}
          </div>
              <MessageForm chatId={this.props.chatId} current_membership= {current_membership} />
        </div>
      );
    } else {
      return (
        <div className="message-list block shadow">
          <div
            className="message-list__content"
            ref={(list) => {
              this.list = list;
            }}
          >
            <div className="message-list__empty-list">
              <div className="message-list__empty-list__image"></div>
              <h3>Aucun message pour l’instant…</h3>
              <p>Mais n'hésitez pas à nous contacter !</p>
            </div>
          </div>

              <MessageForm chatId={this.props.chatId} current_membership= {current_membership} />
        </div>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  const chatId = parseInt(ownProps.chatId, 10);
  return {
    userToken: state.userToken,
    messages: state.messages,
    notifs: state.notifs.filter((n) => {
      return (
        n.read_at == null &&
        n.notifiable_type === "Message" &&
        n.action === "received" &&
        chatId === n.chat_id
      );
    }),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchMessages, markAsRead }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
