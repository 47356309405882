import React from "react";

const NoProposalListView = (props) => {
  const { title, message } = props;

  return (
    <div className="proposal-list block shadow">
      <h4>Devis reçus</h4>
      <div className="proposal-list__empty-list">
        <div className="proposal-list__empty-list__image"></div>
        <center>
          {" "}
          <h3>{title}</h3>
        </center>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default NoProposalListView;