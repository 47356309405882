import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Quote from '../../components/quote/quote';
import QuoteModal from '../../components/quote_modal/quote_modal'
import { postFeedback } from '../../actions/index';
import "./provider_proposal.css";



import { fetchQuotes } from '../../actions/index';

class ProviderProposal extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.proposal.feedback };
  }

  componentWillMount() {
     this.props.fetchQuotes(this.props.proposal.id)
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.postFeedback(this.props.proposal.id, this.state.value);
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  }


  render() {
    return (
      <div className="provider-proposal block shadow">
          <div className="basic-form">
            <h4>L'avis du concierge</h4>
            <form onSubmit={this.handleSubmit} className="provider-proposal__feedback-form">
              <textarea className="provider-proposal__feedback"  autoComplete="off" type="text" defaultValue={this.state.value} onChange={this.handleChange} ></textarea>
              <button type="submit" className="blue-button">Valider l'avis</button>
            </form>
          </div>
          <h4>Devis prestataire</h4>
          {
            this.props.quotes.map((quote) => {
              return <Quote key={quote.id} quote={quote} scope='caretaker' needId={this.props.needId} />;
            })
          }
        <QuoteModal proposal={this.props.proposal.id}/>
      </div>
    );
  }
}





function mapStateToProps(state) {
  return {
    quotes: state.quotes
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchQuotes, postFeedback }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderProposal);

