import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactHtmlParser from 'react-html-parser';
import "./contact_modal.css";


class ContactModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () =>{
    this.setState({ showModal: false });
  }

  open = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  renderContact = (role) => {
    if (role == "provider") {
      return (
            <tbody>
                <tr valign="top">
                   <td>Client :</td>
                   <td><span>{this.props.need.client_contact}</span></td>
                </tr>
              </tbody>
            )
        } else if (role == "client") {
        return (
          <tbody>
              <tr valign="top">
                <td>Prestataire :</td>
                <td><span>{this.props.need.provider_contact}</span></td>
              </tr>
            </tbody>
          )
        } else {
          return (
            <tbody>
                <tr valign="top">
                   <td>Client :</td>
                   <td><span>{this.props.need.client_contact}</span></td>
                </tr>
                <tr valign="top">
                  <td>Prestataire :</td>
                  <td><span>{this.props.need.provider_contact}</span></td>
                </tr>
              </tbody>
            )
        }
    }

  render() {

    return (
      <li>
          <a href="" onClick={this.open}><div className="icon guy"></div>Votre interlocuteur</a>

          <Modal id="contactModal" className="contact-modal" show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>
            <h4 className="modal-title" id="exampleModalLabel">Interlocuteurs
            </h4>
            </Modal.Title>
          </Modal.Header>
            <Modal.Body>
              <table>
                {this.renderContact(this.props.role)}
              </table>
            </Modal.Body>
          </Modal>
      </li>
    );
  }
};

export default ContactModal;
