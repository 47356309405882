// NB: I tried to make this an ERB file to read ruby ivar & constants
//     but does not seem to work. It would be great to fix this so I can use
//     state constant instead

import { MembreNew } from "../members_components/members_new";
import "./members_new.css";

const selector = "#js-members_new__members-root";
const reactRootDomEl = document.getElementById(selector.substring(1));
const reactRootDomSelectors = {};

if (reactRootDomEl) {
  reactRootDomSelectors[selector] = {
    component: MembreNew,
  };
}

export { reactRootDomSelectors };
