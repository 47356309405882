import React, { Component } from 'react';
import FileViewer from 'react-file-viewer';
import ReactDataGrid from 'react-data-grid';
import "./document_library.css";

class DocumentLibrary extends Component {

  constructor(props) {
      super(props);
      this.state = {
        filter: ["Image", "Visit", "Other", "Testify"],
        Image: true,
        Visit: true,
        Other: true,
        Testify: true
      }
    }


  renderShare = (file, typeTrad, scope, current_user) => {
    if (scope == "caretaker") {
      return (
          <a onClick={() => this.props.shareAttachment(file.filename)}><div className={typeTrad[file.shareable]}></div></a>
        )
      }
  }


  renderDestroy = (file, scope, current_user) => {
    if (file.user == current_user || scope == "caretaker") {
      return (
         <a onClick={() => this.props.destroyAttachment(file.filename)}>x</a>
        )
      }
  }

  renderRow = (file, scope) => {
    const type = file.url.split('.').pop();
    const typeTrad = {"Image": "Photo", "Visit": "Rapport de visite", "Testify": "Attestation", "Other": "Autre", true:"icon-share shared", false:"icon-share unshared"}
    const current_user = document.getElementById("js-navbar-riing__user-token").dataset.userToken
      if (['xlsx', 'pdf', 'txt', 'docx', 'pptx'].includes(type)) {
        return (
            <tr key={file.id} id={file.id} className="file-cont">
              <td><a href={file.url} target="_blank"><div className={type}></div></a></td>
              <td>{decodeURIComponent(file.url.split('/').pop())}</td>
              <td><span className="notice">Posté par :</span><br />{file.user_detail}</td>
              <td><span className="notice">Le :</span><br />{file.created_at}</td>
              <td>{typeTrad[file.content_type]}</td>
              <td>{this.renderShare(file, typeTrad, scope, current_user)}</td>
              <td>{this.renderDestroy(file, scope, current_user)}</td>
            </tr>
          )
      } else if (['jpeg', 'jpg', 'png', 'JPG'].includes(type)) {
        return (
            <tr key={file.id} id={file.id} className="file-cont">
              <td>
                <a href={file.url} target="_blank">
                  <div className={type}>
                    <img src={file.url} alt={decodeURIComponent(file.url.split('/').pop())} />
                  </div>
                </a>
              </td>
              <td>{decodeURIComponent(file.url.split('/').pop())}</td>
              <td><span className="notice">Posté par :</span><br />{file.user_detail}</td>
              <td><span className="notice">Le :</span><br />{file.created_at}</td>
              <td>{typeTrad[file.content_type]}</td>
              <td>{this.renderShare(file, typeTrad, scope, current_user)}</td>
              <td>{this.renderDestroy(file, scope, current_user)}</td>
            </tr>
          )
      } else {
        return  (
          <tr key={file.id} id={file.id} className="file-cont">
            <td>
              <a href={file.url} target="_blank">
                <FileViewer fileType={type} filePath={file.url} />
              </a>
            </td>
            <td>{decodeURIComponent(file.url.split('/').pop())}</td>
            <td><span className="notice">Posté par :</span><br />{file.user_detail}</td>
            <td><span className="notice">Le :</span><br />{file.created_at}</td>
            <td>{typeTrad[file.content_type]}</td>
            <td>{this.renderShare(file, typeTrad, scope, current_user)}</td>
            <td>{this.renderDestroy(file, scope, current_user)}</td>
          </tr>
        )
      }
  }

  setFilters = () => {
    const elem = event.toElement
    const filters = document.getElementById("docFilters").getElementsByTagName('input')
    let checkedFilters = []
    for (const [key, value] of Object.entries(filters)) {
      if (value.checked) {
        checkedFilters.push(value.value)
      }
    }
    this.setState({ filter: checkedFilters, [elem.name]: elem.checked })
  }

  renderFilters = (filters) => {
    return (
      <div id="docFilters" className="filters">
       {filters.map((key, index) => {
          const type = key.key
          return (
            <span key={index} className="form-check">
              <input
                name={key.key}
                id={key.key}
                type="checkbox"
                value={key.key}
                onChange={this.setFilters}
                checked={this.state[key.key]}
              />
              <label className="filter-label" htmlFor={key.key}>{key.value}</label>
            </span>
          )
        }
      )}
     </div>
     )
  }

  renderContent = (filteredElements) => {
    if (filteredElements.length == 0) {
      return <tr><td><div className="drop-zone"><a className="link" onClick={this.props.importStep}>Ajouter un document</a></div></td></tr>
    } else {
      return filteredElements
    }
  }

  render() {
    const { files } = this.props
    const filterStr = this.state.filter
    const scope = document.getElementById("document-library").dataset.scope

    const filteredElements = files.filter(file => filterStr
      .includes(file.content_type))
      .map(file =>
        { return this.renderRow(file, scope) }
      )

    const filters = [{key: "Image", value: "Photo"}, {key: "Visit", value: "Rapport de visite"}, {key: "Testify", value: "Attestation"}, {key: "Other", value: "Autre"}]

    return (
      <div className="document-library">
        <h5>Documents disponibles</h5>
        { this.renderFilters(filters) }
        <div className="document-library__container">
          <table>
            <tbody>
            { this.renderContent(filteredElements) }
          </tbody>
        </table>
        </div>
     </div>
    )
  }
}

export default DocumentLibrary;



