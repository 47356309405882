import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { createBrowserHistory as history } from "history";

import Messenger from "../../components/messenger/messenger";
import NeedDescriptionView from "../../components/need_description_view/need_description_view";
import NeedQualifyView from "../../components/need_qualify_view/need_qualify_view";
import NeedProviderView from "../../containers/need_provider_view/need_provider_view";
import NeedProviderListView from "../../containers/need_provider_list_view/need_provider_list_view";
import NeedCancelledView from "../../components/need_cancelled_view/need_cancelled_view";
import NeedValidatedView from "../../components/need_validated_view/need_validated_view";
import NeedManagementView from "../../components/need_management_view/need_management_view";
import NeedBriefView from "../../components/need_brief_view/need_brief_view";

class NeedRouter extends Component {
  redirectTo = (state, scope, cancelledPath) => {
    if (state == "cancelled") {
      return `/${scope}/needs/:needId/cancelled`;
    } else if (state == "refused") {
      return `/${scope}/needs/:needId/cancelled`;
    } else if (["validated", "paid", "done"].includes(state)) {
      return scope == "caretaker"
        ? `/${scope}/needs/:needId/management`
        : `/${scope}/needs/:needId/validated`;
    } else {
      return scope == "caretaker"
        ? `/${scope}/needs/:needId/client`
        : `/${scope}/needs/:needId/description`;
    }
  };

  render() {
    const props = this.props;
    const scope = props.scope;
    const ratable = props.ratable;
    const current_membership = props.currentMembership;
    let state = props.state;

    if (state == "refused") {
      state = "cancelled";
    } else if (state == "paid") {
      state = "validated";
    }

    const descriptionPath = `/client/needs/:needId/description`;
    const messengerPath = `/client/needs/:needId/chats/:chatId`;
    const cancelledPath = `/client/needs/:needId/cancelled`;
    const clientValidatedPath = `/client/needs/:needId/validated`;

    const providerDescriptionPath = `/provider/needs/:needId/description`;
    const providerMessengerPath = `/provider/needs/:needId/chats/:chatId`;
    const providerCancelledPath = `/provider/needs/:needId/cancelled`;
    const providerValidatedPath = `/provider/needs/:needId/validated`;

    const qualifyPath = `/caretaker/needs/:needId/client`;
    const providerPath = `/caretaker/needs/:needId/chats/:chatId`;
    const providerListPath = `/caretaker/needs/:needId/providers`;
    const caretakerValidatedPath = `/caretaker/needs/:needId/management`;
    const caretakerCancelledPath = "/caretaker/needs/:needId/cancelled";

    const redirectFrom = `/${scope}/needs/:needId`;
    return (
      <Router history={history}>
        <Switch>
          {/* Routes client */}
          <Route
            path={descriptionPath}
            render={(props) => (
              <NeedDescriptionView {...props} currentMembership={current_membership} scope={scope} state={state} />
            )}
          />
          <Route
            path={messengerPath}
            render={(props) => (
              <Messenger {...props} scope={scope} state={state} />
            )}
          />
          <Route
            path={cancelledPath}
            render={(props) =>
              state == "cancelled" ? (
                <NeedCancelledView {...props} state={state} />
              ) : (
                <Redirect from="cancelled" to="description" />
              )
            }
          />
          <Route
            path={clientValidatedPath}
            render={(props) =>
              state == "validated" ? (
                <NeedValidatedView
                  {...props}
                  state={state}
                  scope={scope}
                  ratable={ratable}
                />
              ) : (
                <Redirect from="validated" to="description" />
              )
            }
          />

          {/* Routes provider */}
          <Route
            path={providerDescriptionPath}
            render={(props) => (
              <NeedBriefView {...props} scope={scope} state={state} />
            )}
          />
          <Route
            path={providerMessengerPath}
            render={(props) => (
              <Messenger
                {...props}
                scope={scope}
                state={state}
                current_membership={current_membership}
              />
            )}
          />
          <Route
            path={providerCancelledPath}
            render={(props) =>
              state == "cancelled" || state == "refused" ? (
                <NeedCancelledView {...props} state={state} />
              ) : (
                <Redirect from="cancelled" to={state} />
              )
            }
          />

          <Route
            path={providerValidatedPath}
            render={(props) =>
              state == "validated" ? (
                <NeedValidatedView
                  {...props}
                  state={state}
                  scope={scope}
                  ratable={ratable}
                />
              ) : (
                <Redirect from="validated" to={state} />
              )
            }
          />

          {/* Routes caretaker */}
          <Route
            path={qualifyPath}
            render={(props) => (
              <NeedQualifyView {...props} scope={scope} state={state} />
            )}
          />
          <Route
            path={providerPath}
            render={(props) => (
              <NeedProviderView {...props} scope={scope} state={state} />
            )}
          />
          <Route
            path={providerListPath}
            render={(props) => (
              <NeedProviderListView {...props} scope={scope} state={state} />
            )}
          />
          <Route
            path={caretakerValidatedPath}
            render={(props) => (
              <NeedManagementView {...props} state={state} scope={scope} />
            )}
          />
          <Route
            path={caretakerCancelledPath}
            render={(props) =>
              state == "cancelled" ? (
                <NeedCancelledView {...props} state={state} />
              ) : (
                <Redirect from="cancelled" to="description" />
              )
            }
          />

          {/*Redirect*/}
          <Redirect
            from={redirectFrom}
            to={this.redirectTo(state, scope, cancelledPath)}
          />
        </Switch>
      </Router>
    );
  }
}

export default NeedRouter;
