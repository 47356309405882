import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InvoiceForm from '../../containers/invoice_form/invoice_form';
import 'air-datepicker';
import 'air-datepicker/dist/js/i18n/datepicker.fr.js';
import "./invoice_modal.css";

class InvoiceModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () =>{
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }

  setDatepicker = () => {
    $('.datepicker-here').datepicker();
  }

  render() {
    return (
      <>
        <Button className="blue-button" onClick={this.open}>
          Importer une facture
        </Button>

        <Modal id="newInvoiceModal" show={this.state.showModal} onHide={this.close} onEntered={this.setDatepicker}>
          <Modal.Header closeButton>
            <Modal.Title>Nouvelle facture</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InvoiceForm close={this.close} chatId={this.props.chatId} />
          </Modal.Body>
        </Modal>
      </>
    );
  }

}


export default InvoiceModal;
