export default function railsFetch(url, options = {}) {
  // grab Rails CRSF Token
  const crsfToken = document
    .querySelector("meta[name=csrf-token]")
    .getAttribute("content");

  const railsOptions = {
    headers: {
      // Generate a bunch of headers to make Rails behave
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": crsfToken,
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json; charset=utf-8"
    },
    credentials: "same-origin",
    ...options
  };
  return fetch(url, railsOptions);
}
