import React, { Component } from 'react';
import { emojify } from 'react-emojione';
import "./message.css";


class Message extends Component {

  renderContent = (content = "") => {
    if (this.props.message.type == 'attach') {
        const message = JSON.parse(content);
        return (
          <a href={message.url} target='_blank'>{message.name}</a>
        )
    } else {
        return (
         emojify(content)
        )
    }
  }

  renderInitials = (string) => {
      return (
          <div className="avatar">{string}</div>
      )
  }

  renderAvatar = (avatar) => {
    return (
        <div className="avatar" style={{ backgroundImage: `url(${avatar})` }}></div>
      )
  }

  render() {
    const { message, is_sender: isSender } = this.props

    const date = new Date(message.created_at).toLocaleDateString();
    const time = new Date(message.created_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    const classes = () => {
      return (isSender ? `message--${message.type} message message--is-sender` : `message--${message.type} message`);
    }


    return (
      <div className={classes()}>
        <span className="message__author">
          { message.avatar !== null ? this.renderAvatar(message.avatar) : this.renderInitials(message.initials) }
          {this.props.message.author}
        </span>
        <div className="message__content">{this.renderContent(this.props.message.content)}</div>
        <span className="message__date">{date} - {time}</span>
      </div>

    )
  }

}
export default Message;
