import React from "react";
import Select from "react-select";

export const ClientConnexionSelect = (props) => {
  const { height, width, placeholder, options, onChange } = props;

  const styles = {
    menuListDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      fontSize: "14px",
      verticalAlign: "middle",
      color: "#143456",
    },
    menuListIcon: {
      marginLeft: 10,
    },
    labelStyle: {
      color: "#143456",
      fontSize: "12px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    label: {
      whiteSpace: "nowrap",
      width: "30px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      background: "#fff",
      color: "#000",
      width: "300px",

    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      overflowX: "hidden",
    }),
    control: (provided, state) => ({
      ...provided,
      background:
        "linear-gradient(268.82deg, rgba(237, 81, 78, 0.1) -6.39%, rgba(251, 187, 62, 0.1) 102.53%)",
      borderColor: "#DDDDDD",
      color: "#143456",
      minHeight: height + "px",
      height: height + "px",
      minWidth: width + "px",
      width: width + "px",
    }),
  };

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        primary25: props.menuBackgroundColor,
      },
    };
  }

  const formatOptionLabel = ({ image, label }) => (
    <div style={styles.menuListDiv}>
      <img src={image} />
      <div style={styles.menuListIcon}>
        <div style={styles.labelStyle}>
          <span style={styles.label}>{label}</span>
        </div>
      </div>
    </div>
  );

  const handleChange = (event) => {
    onChange(event.value);
  };

  return (
    <div>
      <Select
        theme={customTheme}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        options={options}
        styles={styles}
        onChange={handleChange}
      />
    </div>
  );
};
