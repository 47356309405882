import React, { Component } from 'react';
import "./rating.css";

class Rating extends Component {

   render() {
      const rateClass=`star-${this.props.rating.rate}`
      return (
          <div className='rating'>
              <div className="rating__avatar"></div>

              <div className="rating__container">

                <div className="rating__container__name">
                  {this.props.rating.name}
                </div>

                <div className="rating__container__rate">
                  <div className={rateClass}>
                    <i className="star"></i>
                    <i className="star"></i>
                    <i className="star"></i>
                    <i className="star"></i>
                    <i className="star"></i>
                  </div>
                  {this.props.rating.date}
                </div>

                <div className="rating__container__comment">
                  {this.props.rating.comment}
                </div>
              </div>

          </div>
      );
    }


};

export default Rating;



