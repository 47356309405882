import React, { Component } from 'react';

import ChatList from '../../containers/chat_list/chat_list';
import MessageList from '../../containers/message_list/message_list';
import ManagementForm from '../../containers/management_form/management_form';

import "./need_management_view.css";

class NeedManagementView extends Component {

  componentDidMount() {
    $('.datepicker-here').datepicker();
  }

  render() {
    const chatId = document.getElementById("caretaker-need").dataset.chat;
    const needId = this.props.match.params.needId
    return (
      <div className="need-management-view">
        <ChatList chatId={chatId} needId={needId} scope={this.props.scope} state={this.props.state}/>
        <ManagementForm needId={needId} />
        <div className="need-management-view__right-col shadow block">
          <div className="message-list__title">
            <h4>Messages à #{chatId}</h4>
          </div>
          <MessageList chatId={chatId} />
        </div>
      </div>
    )
  }

}

export default NeedManagementView;
