import React from "react";
import "./proposal_refs.css";

const ProposalRefs = (props) => {
  return (
    <div className="proposal-refs">
      <div className="proposal-refs__recommendations">
        <span className="sub-subtitle">Recommandations</span>
        <div className="recommendation">
          {props.proposal.recommendation_count}
        </div>
      </div>
      <div className="proposal-refs__mission-count">
        <span className="sub-subtitle">Missions Riing</span>
        <div className="mission-count">{props.proposal.mission_count}</div>
      </div>
      <div className="proposal-refs__client-refs">
        <span className="sub-subtitle">Références clients</span>
        <div className="proposal-refs__client-refs__logos">
          {props.proposal.clients.map((client) => {
            // const client = arr[0]
            if (typeof client != "undefined") {
              const style = { backgroundImage: `url('${client.logo}')` };
              return (
                <div
                  className="proposal-refs__client-refs__logos__client-logo"
                  style={style}
                  key={client.id}
                  title={client.name}
                ></div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ProposalRefs;
