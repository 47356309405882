import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { markAsRead } from "../../actions/index";
import "./notif_list.css";

import DataTable from "../../components/datatable/datatable";

class NotifsList extends Component {
  render() {
    const columns = [
      { Header: "", accessor: "id", disableFilters: true, show: false },
      { Header: "Type", accessor: "notifiable_type", disableFilters: true },
      { Header: "Date", accessor: "created_at", disableFilters: true },
      { Header: "De", accessor: "author", disableFilters: true },
      { Header: "Contenu", accessor: "content", disableFilters: true },
      { Header: "Service", accessor: "service_name", disableFilters: true },
      { Header: "Demande n°", accessor: "need_num", disableFilters: true },
      {
        Header: "Voir",
        accessor: "show_link",
        disableFilters: true,
        clickable: true,
        Cell: ({ row }) => (
          <a
            className="datatable__table__link"
            href={row.values.show_link + `?notif=${row.values.id}`}
          >
            <div className="dashboard__icon notif-list__icon--show"></div>
          </a>
        )
      },
      {
        Header: "",
        accessor: "read_at",
        disableFilters: true,
        clickable: true,
        Cell: ({ row, updateMyData, updateCallback }) => {
          const handleClick = row => event => {
            updateMyData(row.index, "read_at", row.values.id, updateCallback);
          };
          return (
            <a
              className="datatable__table__link"
              onClick={handleClick(row)}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Marquer comme lu"
            >
              <div
                className={`notif-list__icon notif-list__icon--notif notif-list__icon--notif${
                  row.values.read_at ? null : "--not-"
                }read`}
              ></div>
            </a>
          );
        }
      },
      {
        Header: "",
        accessor: "is_read",
        disableFilters: true,
        show: false,
        menu: [
          { val: "false", display: "Non-lues" },
          { val: "true", display: "Lues" }
        ]
      }
    ];

    const data = this.props.notifs;
    return (
      <DataTable
        columns={columns}
        data={data}
        notifs={this.props.notifs}
        updateCallback={this.props.markAsRead}
      />
    );
  }
}

function mapStateToProps(state) {
  return { notifs: state.notifs };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ markAsRead }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotifsList);
