import { FETCH_ANSWERS, POST_ANSWERS } from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_ANSWERS: {
      return action.payload;
    }
    case POST_ANSWERS: {
      return action.payload;
    }
    default:
      return state;
  }
}
