import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import QuoteForm from '../../containers/quote_form/quote_form';
import 'air-datepicker';
import 'air-datepicker/dist/js/i18n/datepicker.fr.js';
import "./quote_modal.css";

class QuoteModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () =>{
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }

  setDatepicker = () => {
    $('.datepicker-here').datepicker();
  }

  render() {
    return (
      <>
        <Button className="reverse-red-button add-quote" onClick={this.open}>
          Ajouter un devis
        </Button>

        <Modal id="newQuoteModal" show={this.state.showModal} onHide={this.close} onEntered={this.setDatepicker}>
          <Modal.Header closeButton>
            <Modal.Title>Nouveau devis</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QuoteForm proposal={this.props.proposal} close={this.close}/>
          </Modal.Body>
        </Modal>
      </>
    );
  }

}


export default QuoteModal;
