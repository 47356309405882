// NB: I tried to make this an ERB file to read ruby ivar & constants
//     but does not seem to work. It would be great to fix this so I can use
//     state constant instead

import MembersList from "../../containers/members_list/members_list";
import "./members.css";

const selector = "#js-members__members-root";
const reactRootDomEl = document.getElementById(selector.substring(1));
const reactRootDomSelectors = {};

if (reactRootDomEl) {
  reactRootDomSelectors[selector] = {
    component: MembersList
  };
}

export { reactRootDomSelectors };
