import React from "react";
import { Modal } from "react-bootstrap";
import "./client_modal.css";

export const ClientModal = (props) => {
  const { show, handleClose } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        id="clientModal"
        className="client-modal"
      >
        <Modal.Header closeButton>
          <div>
            <p className="headerText"> Accéder à mon espace client</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="clientText">
              <p className="partnerText">
                {" "}
                En tant que partenaire, vous n’avez pas d’accès client. Vous
                pouvez, si vous le souhaitez devenir client et faire profiter de
                vos services les utilisateurs de Riing .<br></br>Discutons en
                sur <b>concierge@riing.fr</b>
              </p>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
