import React, { useState } from "react";

const shapeIcon = require("./static/Shape.png");
const checkIcon = require("./static/checked.png");
const shapeInvisibleIcon = require("./static/ShapeInvisible.png");

export const ParticipantsIconVisibility = ({ visible, handleChange }) => {
  const [isVisible, setIsVisible] = useState(visible);

  const Styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    checkIconStyle: {
      marginLeft: "-6px",
    },
  };

  return (
    <div style={Styles.container} onClick={handleChange}>
      {isVisible ? (
        <div>
          <img src={shapeIcon} height={14} />
          <img src={checkIcon} height={10} style={Styles.checkIconStyle} />
        </div>
      ) : (
        <div>
          <img src={shapeInvisibleIcon} height={14} />
        </div>
      )}
    </div>
  );
};
