import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { appendMessage, fetchChats, fetchMessages, markAsRead, subscribeToChannel, unsubscribeFromChannel } from '../../actions/index';
import { Link } from 'react-router-dom';
import InvoiceModal from '../../components/invoice_modal/invoice_modal';
import "./chat_list.css";

import NotifCounter from '../../containers/notif_counter/notif_counter';

class ChatList extends Component {
  componentWillMount() {
    this.props.fetchChats(this.props.needId);
  }
  componentDidMount() {
    let callback = (message) => {
      if (message.chat_id === this.props.chatId) {
        this.props.appendMessage(message);
      }
    }
    this.props.subscribeToChannel("ChatChannel" ,parseInt(this.props.chatId), callback)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.chatId !== this.props.chatId) {
      this.props.fetchMessages(nextProps.chatId);
    }
  }

  componentWillUnmount() {
    this.props.unsubscribeFromChannel("ChatChannel", parseInt(this.props.chatId), null)
  }

  countNotif = (chat) => {
    return this.filterNotifs(chat).length;
  }

  filterNotifs = (chatId) => {
    return this.props.notifs.filter(n => {
      return n.notifiable_type === 'Message' && n.action === 'received' && chatId === n.chat_id
    });
  }

  renderNeed = (props) => {
    const clientPath = `/client/needs/${this.props.needId}`
    const providerPath = `/provider/needs/${this.props.needId}`
    const caretakerPath = `/caretaker/needs/${this.props.needId}`
    const validated_state = ["validated", "paid"]
    if (this.props.scope == 'caretaker' && validated_state.includes(this.props.state)) {
      return (
        <li role="management" onClick={this.removeChat}
        className={'management' == window.location.href.substring(window.location.href.lastIndexOf('/') + 1) ? 'active' : null}>
          <Link to={caretakerPath}>Management</Link>
        </li>
        );
    } else if (this.props.scope == 'client') {
      return (
        <li role="presentation" onClick={this.removeChat}
        className={'null' == this.props.chatId ? 'active' : null}>
          <Link to={clientPath}>Votre demande</Link>
        </li>
        );
    } else if (this.props.scope == 'provider') {
      return (
        <li role="presentation" onClick={this.removeChat}
        className={'null' == this.props.chatId ? 'active' : null}>
          <Link to={providerPath}>Brief</Link>
        </li>
        );
    }
  }

  renderProviderList = (props) => {
    const closed_state = ["validated", "paid", "refused", "cancelled"]
    const path = `/caretaker/needs/${this.props.needId}/providers`
    if (this.props.scope == 'caretaker' && closed_state.includes(this.props.state)) {
      return (
          <InvoiceModal chatId={this.props.chatId} />
        );
    } else if (this.props.scope == 'caretaker') {
      return (
        <Link to={path} className="blue-button">Ajouter un prestataire</Link>
      );
    }
  }



  handleClick = (event) => {
    const chatId = parseInt(event.target.dataset.chatId || event.currentTarget.dataset.chatId, 10);
    const notifIds = this.filterNotifs(chatId).map(n=> n.id)
    if (notifIds.length > 0) { this.props.markAsRead(notifIds); }
  }

  renderLink = (chat) => {
    if (chat.recipient_type == 'client' && this.props.scope != 'client') {
      const path = `/${this.props.scope}/needs/${this.props.needId}/`
      return (
        <Link to={`${path}${chat.recipient_type}`}>Demande</Link>
      )
    } else {
      const path = `/${this.props.scope}/needs/${this.props.needId}/chats/`;
      let link_name = ''
      if (this.props.scope == 'client' || this.props.scope == 'provider') {
        link_name = chat.recipient
      } else {
        link_name = chat.recipient_firm
      }
      return (
        <Link to={`${path}${chat.id}`}>{link_name}</Link>
        )
      }
  }

  renderChat = (chat) => {
    return (
      <li key={chat.id} role="presentation" data-chat-id={chat.id} onClick={this.handleClick}
        className={(chat.id == this.props.chatId && 'management' != window.location.href.substring(window.location.href.lastIndexOf('/') + 1)) ? 'active' : null}
      >
        {this.renderLink(chat)}
        <NotifCounter count={this.countNotif(chat.id)} chatId={chat.id}/>
      </li>
    );
  }

  render() {
    return (
      <div id="chatList" className="chat-list block">
        <ul className="chat-list__list">
          {this.renderNeed()}
          {this.props.chats?.length > 0 && this.props.chats.map(this.renderChat)}
        </ul>
        {this.renderProviderList()}
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    chats: state.chats,
    messages: state.messages,
    notifs: state.notifs.filter(n => { return n.read_at == null && n.notifiable_type === 'Message' && n.action === 'received' })
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ appendMessage, fetchMessages, fetchChats, markAsRead, subscribeToChannel, unsubscribeFromChannel }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatList);
