import React from "react";
import Select from "react-select";

const styles = {
  menu: (base) => ({
    ...base,
    fontSize: "0.7em",
    fontWeight: "500",
    borderRadius: 0,
    marginTop: 0,
    background: "#fff",
    color: "#143456",
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#F0F4F6",
    color: "#143456",
    height: "2em",
    width: "10em",
    border: "0px",
    fontSize: "1.2em",
    fontWeight: "500",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const MemberListSelect = (props) => {
  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        primary25: props.menuBackgroundColor,
        parimary: props.menuBackgroundColory,
      },
    };
  }

  return (
    <Select
      theme={customTheme}
      styles={styles}
      {...props}
      menuPortalTarget={document.body}
    />
  );
};
