// NB: I tried to make this an ERB file to read ruby ivar & constants
//     but does not seem to work. It would be great to fix this so I can use
//     state constant instead

import ParticipantsListAll from "../../containers/participants_list_all/participants_list_all";
import "./participants_list_all.css";

const selector = "#js-participants__members_all-root";
const reactRootDomEl = document.getElementById(selector.substring(1));
const reactRootDomSelectors = {};

if (reactRootDomEl) {
  reactRootDomSelectors[selector] = {
    component: ParticipantsListAll,
  };
}

export { reactRootDomSelectors };
