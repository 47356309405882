import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchProposals } from "../../actions/index";
import Proposal from "../../components/proposal/proposal";
import "./proposal_list.css";
import NoProposalListView from "./no_proposal_view";

class ProposalList extends Component {
  componentWillMount() {
    this.props.fetchProposals(this.props.needId, true, null);
  }
  render() {
    const { title, message, scope, proposals } = this.props;
    const hasQuotes =
      proposals.length > 0 && proposals.flatMap((p) => p.quotes).length > 0;

    if (scope !== "provider" && hasQuotes) {
      return (
        <div className="proposal-list block shadow">
          <h4>Devis reçus</h4>
          {proposals.map((proposal, index) => {
            return (
              <Proposal
                key={index}
                place={index + 1}
                proposal={proposal}
                needId={this.props.needId}
              />
            );
          })}
        </div>
      );
    } else {
      return <NoProposalListView title={title} message={message} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    proposals: state.proposals,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchProposals }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalList);
