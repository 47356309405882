import React, { useState, useMemo } from "react";
import { groupBy } from "lodash";
import { ParticipantsUpdateModal } from "../participants_update_modal/participants_update_modal";
import { ParticipantsAllTableRow } from "./participants_all_table_row";
import { ProvidersTable } from "./providers_table";
import "./participants_all_table.css";
import { ParticipantsAllHeader } from "./participants_all_header";

export const ParticipantsAllTable = (props) => {
  const {
    allParticipations,
    currentMembership,
    needid,
    providerFirms,
    currentMembership: { side, role },
  } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const sortedParticipants = allParticipations.sort((a, b) => {
    return a.id < b.id;
  });
  let clients = sortedParticipants.filter(
    (participant) => participant.membership.side === "client"
  );

  let providers = sortedParticipants.filter(
    (participant) => participant.membership.side === "provider"
  );

  let caretakers = sortedParticipants.filter(
    (participant) => participant.membership.side === "caretaker"
  );
  const [clickedParticipationId, setClickedParticipationId] = useState(null);

  const selectedParticipation = useMemo(() => {
    if (clickedParticipationId === null) return null;
    return allParticipations.find((m) => m.id === clickedParticipationId);
  }, [clickedParticipationId]);

  const handleClick = (participationId) => {
    setClickedParticipationId(participationId);
    setShow(true);
  };

  if (side === "provider") {
    clients = clients.filter((p) => p.visible);
    caretakers = caretakers.filter((p) => p.visible);
  }

  if (side === "client") {
    providers = providers.filter((p) => p.visible);
    caretakers = caretakers.filter((p) => p.visible);
  }

  const clientPath = `/client/needs/${needid}`;
  const providerPath = `/provider/needs/${needid}`;
  const caretakerPath = `/caretaker/needs/${needid}`;
  const membersPath = `/members`;
  const needParticipationsPath = `/needs/${needid}/participations`;

  const providersByFirmId = groupBy(providers, (p) => p.membership.firm_id);

  const firms =
    side === "provider"
      ? providerFirms.filter((f) => f.id == currentMembership.firm_id)
      : providerFirms;

  return (
    <>
      <div className="participants_all_header">
        <a
          className="participants_all_header__link"
          href={
            side === "caretaker"
              ? caretakerPath
              : side === "client"
              ? clientPath
              : providerPath
          }
        >
          <span className="participants-all-header__title">
            &#60; Demande N&deg; {needid}{" "}
          </span>
        </a>
        <span className="participants-all-header__title">
          Tous les membres pour cette demande
        </span>
        <a
          className="header_btn_participants_link_btn"
          href={needParticipationsPath}
        >
          {" "}
          <span className="header-link-participants__link__btn">
            + &nbsp; Gérer les membres
          </span>
        </a>
      </div>
      <ParticipantsAllHeader currentMembership={currentMembership} />
      <div>
        <span className="group-item-text">Client</span>
        {clients.map((p) => {
          return (
            <ParticipantsAllTableRow
              key={p.id}
              participant={p}
              side="client"
              handleClick={handleClick}
              currentMembership={currentMembership}
            />
          );
        })}

        {firms.map((f) => {
          return (
            <ProvidersTable
              key={f.id}
              firm={f}
              members={providersByFirmId}
              currentSide="provider"
              handleClick={handleClick}
              currentMembership={currentMembership}
            />
          );
        })}

        <span className="group-item-text">Concierge</span>

        {caretakers.map((p) => {
          return (
            <ParticipantsAllTableRow
              key={p.id}
              participant={p}
              side="caretaker"
              handleClick={handleClick}
              currentMembership={currentMembership}
            />
          );
        })}
        <ParticipantsUpdateModal
          currentMembership={currentMembership}
          show={show}
          participation={selectedParticipation}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};
