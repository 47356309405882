import React, { useState, useMemo } from "react";
import "./participants_all_table.css";

export const ParticipantsAllHeader = (props) => {
  const {
    currentMembership: { side, role },
  } = props;

  return (
    <header className="table-row table-row--6col table-row--header">
      <div className="table-row__col">
        <span className="table-row__title">
          Visible / <br /> invisible
        </span>
      </div>
      <div className="table-row__col table-row__col--start">
        <span className="table-row__title">Fonction</span>
      </div>
      
        <div className="table-row__col table-row__col--start">
           {side === "caretaker" || role === "accountant" && <span className="table-row__title">Email</span>} 
        </div>

      <div className="table-row__col table-row__col--start">
        <span className="table-row__title">Fixe</span>
      </div>
      <div className="table-row__col table-row__col--start">
        <span className="table-row__title">Tel</span>
      </div>
      <div className="table-row__col table-row__col--start">
        <span className="table-row__title">Instructions particulieres</span>
      </div>
    </header>
  );
};
