import React, { Component } from 'react';
import ProposalRefs from '../../components/proposal_refs/proposal_refs';
import ProviderProfile from '../../components/provider_profile/provider_profile';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./validate_quote.css";

class ValidateQuote extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      validate: false
    };
  }

  close = () =>{
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }

  setValidate = () => {
    this.setState({ validate: event.target.checked });
  }

  render() {
    const validatePath = `/client/needs/${this.props.needId}/quotes/${this.props.quote.id}?state=selected`
    return (
      <>
          <Button className="red-button" onClick={this.open}>
            Valider
          </Button>

          <Modal id="validateQuoteModal" className="validate-quote-modal" show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>
            <h4>
              Confirmez-vous la prestation de la société {this.props.quote.firm_name} ?
            </h4>
            </Modal.Title>
          </Modal.Header>
            <Modal.Body>
                <span className="form-check">
                  <input
                    name="validate"
                    id="validate"
                    type="checkbox"
                    onChange={this.setValidate}
                    checked={this.state.validate}
                  />
                  <label className="filter-label" htmlFor="validate">J'autorise Riing à provisioner le montant du devis afin de rémunérer le prestataire une fois la mission effectuée</label>
                </span>
            </Modal.Body>
            <Modal.Footer>
              <Button href={validatePath} data-method="put" className="red-button" disabled={!this.state.validate}>Valider ce devis</Button>
              <div className="notice">En cochant cette case, j'accepte les <a href="https://www.riing.fr/politique-de-confidentialite/" target="_blank">conditions générales de Riing</a> sans réserve.</div>
            </Modal.Footer>
          </Modal>
      </>
    );
  }
};

export default ValidateQuote;



