import React from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./custom_select.css";

export const CustomSelect = (props) => {
  const { height, placeholder, options, onChange, handleShow } = props;

  const styles = {
    menuListDiv: {
      display: "flex",
      fontSize: "14px",
      verticalAlign: "middle",
      color: "#143456",
    },
    menuListIcon: {
      marginLeft: "auto",
      order: 2,
    },
    control: (base, state) => ({
      ...base,
      backgroundColor: "#fff",
      borderColor: "#DDDDDD",
      color: "#143456",
      minHeight: height + "px",
      height: height + "px",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      background: "white",
    }),
  };

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        // here need to tronsform linear color to one color
        primary25: props.menuBackgroundColor,
      },
    };
  }

  const formatOptionLabel = ({ value, label }) => (
    <div style={styles.menuListDiv}>
      <div>{label}</div>
      <div style={styles.menuListIcon} onClick={handleShow}>
        <FontAwesomeIcon icon={faInfoCircle} color="#F38047" />
      </div>
    </div>
  );

  const handleChange = (event) => {
    onChange(event.value);
  };

  return (
    <div>
      <Select
        theme={customTheme}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        options={options}
        styles={styles}
        onChange={handleChange}
      />
    </div>
  );
};
