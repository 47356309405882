import React from "react";
import { ParticipantsAllTableRow } from "./participants_all_table_row";

export const ProvidersTable = (props) => {
  const { firm, currentMembership, members, handleClick } = props;

  return (
    <div key={firm.id}>
      <span key={firm.id} className="group-item-text">
        Prestataire : {firm.name}
      </span>
      {members[firm.id]?.map((p) => {
        return (
          <ParticipantsAllTableRow
            key={p.id}
            participant={p}
            side="provider"
            handleClick={handleClick}
            currentMembership={currentMembership}
          />
        );
      })}
    </div>
  );
};
