import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FileViewer from 'react-file-viewer';
import ReactDataGrid from 'react-data-grid';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./document_library_widget.css";
import DocumentLibrary from '../../components/document_library/document_library'
import DropZoneLib from '../../components/drop_zone_lib/drop_zone_lib'
import { fetchAttachments, destroyAttachment, shareAttachment } from '../../actions/index';

class DocumentLibraryWidget extends Component {

   constructor(props) {
      super(props);
      this.state = {
        showModal: false,
        step: 0,
        type: ""
      };
    }

    componentWillMount() {
      this.props.fetchAttachments(this.props.need.id, this.props.userId);
    }

    close = () =>{
      this.setState({ showModal: false });
      document.getElementById('attach_count').textContent = this.props.attachments.length
    }

    open = () => {
      this.setState({ showModal: true, step: 0 });
    }

    destroyAttachment = (filename) => {
      this.props.destroyAttachment(filename)
    }

    shareAttachment = (filename) => {
      this.props.shareAttachment(filename)
    }

    submitContent = () => {
      this.props.fetchAttachments(this.props.need.id, this.props.userId);
      this.setState({ showModal: true, step: 0 });
    }

    importStep = () => {
      this.setState({ showModal: true, step: 1 });
    }

    renderContent = () => {
      if (this.state.step == 0) {
        return (
          <div>
            <a onClick={() => this.onClick(1, "Image")} className="gradiant-orange-button modal-button">
              + Ajouter un document
            </a>
            <DocumentLibrary files={this.props.attachments} destroyAttachment={this.destroyAttachment} shareAttachment={this.shareAttachment} importStep={this.importStep} />
          </div>
          )
      } else if (this.state.step == 1) {
        return (
          <div className='document-categories'>
            <a onClick={() => this.onClick(2, "Image")}>
              <div className="pic photo"></div>Photo
            </a>
            <a onClick={() => this.onClick(2, "Visit")}>
              <div className="pic visit"></div>Rapport de visite
            </a>
            <a onClick={() => this.onClick(2, "Testify")}>
              <div className="pic testify"></div>Attestation
            </a>
            <a onClick={() => this.onClick(2, "Other")}>
              <div className="pic other"></div>Autre
            </a>
          </div>
          )
      } else if (this.state.step == 2) {
        return <DropZoneLib type={this.state.type} recipient={this.props.need.id} submitContent={this.submitContent} />
      }
    }

    onClick = (step, type) => {
       this.setState({ step: step, type: type} );
    }

  render() {

    return (
      <div className="document-library">
         <Modal id="documentsModal" className="documents-modal" show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>
            <h2 className="modal-title">
              Documents
            </h2>
            </Modal.Title>
          </Modal.Header>
            <Modal.Body>
                { this.renderContent() }
            </Modal.Body>
          </Modal>
          <a className="black-button" href="#" onClick={this.open}>
            Gérer les documents
          </a>
     </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    need: state.need,
    attachments: state.attachments,
    userId: state.userToken
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({fetchAttachments, destroyAttachment, shareAttachment}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentLibraryWidget);


