import { FETCH_ATTACHMENTS, DESTROY_ATTACHMENT, SHARE_ATTACHMENT } from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_ATTACHMENTS: {
      return action.payload;
    }
    case DESTROY_ATTACHMENT: {
      return state.filter(element => element.filename !== action.payload);
    }
    case SHARE_ATTACHMENT: {
      return action.payload;
    }
    default:
      return state;
  }
}
