import { FETCH_PROPOSALS, FEEDBACK_POSTED } from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_PROPOSALS: {
      return action.payload;
    }
    case FEEDBACK_POSTED: {
      return [action.payload];
    }
    default:
      return state;
  }
}
