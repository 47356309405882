import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createMessage } from "../../actions/index";
import "./message_form.css";

class MessageForm extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.createMessage(
      this.props.chatId,
      this.props.currentUser,
      this.state.value
    );
    this.setState({ value: "" }); // Reset message input
  };

  render() {
    const { current_membership } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className="message-form">
        <div
          className="message-form__attach"
          data-toggle="modal"
          data-target="#fileModal"
          data-chat-id={this.props.chatId}
        ></div>
        <textarea
          ref={(input) => {
            this.messageBox = input;
          }}
          type="text"
          required
          className="form-control message-form__input"
          autoComplete="off"
          value={this.state.value}
          onChange={this.handleChange}
        />
        <button type="submit" className="message-form__submit"></button>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createMessage }, dispatch);
}

function mapStateToProps(state) {
  return {
    currentUser: state.userToken,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageForm);
