import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { postInvoice } from '../../actions/index';
import "./invoice_form.css";

class InvoiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      amount_incl: '',
      amount_excl: '',
      date: ''
    };
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({ [event.target.name]: value });
   }

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.postInvoice(this.state, this.props.chatId);

    this.setState({
      url: '',
      amount_incl: '',
      amount_excl: '',
      date: ''
    });

    this.props.close()
  }

  render() {

      return (
          <div className="invoice-form">
          <form onSubmit={this.handleSubmit} className="invoice-form__form">
             <div className="basic-form">

                 <div className="form-group">
                    <label className="form-control-label">lien Sellsy</label>
                    <input className="form-control string" type="url" name="url" value={this.state.url} onChange={this.handleChange} />
                 </div>

                 <div className="form-group">
                    <label className="form-control-label">Montant HT</label>
                    <input className="form-control decimal" type="number" name="amount_excl" value={this.state.amount_excl} onChange={this.handleChange} />
                 </div>

                 <div className="form-group">
                    <label className="form-control-label">Montant TTC</label>
                    <input className="form-control decimal" type="number" name="amount_incl" value={this.state.amount_incl} onChange={this.handleChange} />
                 </div>

                 <div className="form-group string">
                    <label className="form-control-label string">Date de facturation</label>
                    <input className="form-control string datepicker-here" data-language="fr" autoComplete="off" name="date" value={this.state.date} onChange={this.handleChange} onBlur={this.handleChange} />
                </div>

              </div>

              <div className="invoice-form__buttons">
                <button type="submit" className="red-button">Enregistrer</button>
              </div>

            </form>
        </div>
      )
    }

  };


function mapStateToProps(state) {
  return {
    need: state.need,
    invoice: state.selectedInvoice
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postInvoice }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);
