import { FETCH_NEED, REQUALIFY_NEED, MANAGE_NEED, POST_NEED_FILE, REQUALIFY_NEED_ERROR, MANAGE_NEED_ERROR } from "../actions";

const initialState = {
    errors: '',
    success: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_NEED: {
      return action.payload;
    }
    case REQUALIFY_NEED: {
      return {
                ...state,
                errors: '',
                success: action.payload.message
              }
    }
    case REQUALIFY_NEED_ERROR: {
      return {
                ...state,
                success: '',
                errors: action.payload.message
            }
    }
    case MANAGE_NEED: {
      return {
                ...state,
                errors: '',
                success: action.payload.message
              }
    }
    case MANAGE_NEED_ERROR: {
      return {
                ...state,
                success: '',
                errors: action.payload.message
            }
    }
    default:
      return state;
  }
}
