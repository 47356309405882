import React, { useState } from "react";
import { MemberListSelect } from "../member_list_select/member_list_select";
import { DeleteModal } from "../delete_modal/delete_modal";
import { ChangeRoleModal } from "../change_role_modal/change_role_modal";
import { Checkbox } from "../custom_checkbox/Checkbox";
import putJSON from "../../ajax/putJSON";
import { DefaultResponderModal } from "../default_responder_modal/default_responder_modal";

const deleteIcon = require("./static/delete.png");

const options = [
  { value: "owner", label: "Super admin" },
  { value: "admin", label: "Admin" },
  { value: "common", label: "Utilisateur" },
  { value: "worker", label: "Intervenant" },
  { value: "accountant", label: "Comptable" },
];

export function MembreTableRow({ membership, currentUserMembership }) {
  const { user: currentUser, role: currentUserRole } = currentUserMembership;
  const canDelete = (member) => {
    if (member.id === currentUser.id) return false;
    if (role === "owner") return false;
    if (currentUserRole === "owner") return true;
    if (currentUserRole === "admin" && member.role !== "owner") return true;
    return true;
  };

  const {
    user: member,
    role: memberRole,
    verified,
    default_responder: defaultResponder,
  } = membership;
  const [role, setRole] = useState(memberRole);
  const [previousRole, setPreviousRole] = useState(memberRole);
  const [loading, setLoading] = useState(false);
  const value = options.find((option) => option.value === role);
  const [show, setShow] = useState(false);
  const [showChangeRole, setShowChangeRole] = useState(false);
  const [showDefaultResponder, setShowDefaultResponder] = useState(false);

  const [checked, setChecked] = React.useState(defaultResponder);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseChangeRole = (withError = true) => {
    if (withError) {
      setRole(previousRole);
    }
    setShowChangeRole(false);
  }
  const handleShowChangeRole = () => setShowChangeRole(true);

  const handleCloseDefaultResponder = () => setShowDefaultResponder(false);
  const handleShowDefaultResponder = () => setShowDefaultResponder(true);
  const isSelf = member.id === currentUser.id;
  const canEdit =
    currentUserRole === "owner" ||
    (currentUserRole === "admin" && memberRole !== "owner");
  const canEditDefaultResponder =
    (currentUserMembership.side !== "caretaker" &&
      (currentUserRole === "owner" ||
        (currentUserRole === "admin" && memberRole !== "owner"))) ||
    (currentUserMembership.side === "caretaker" && currentUserRole === "owner");

  const availableOptions =
    currentUserRole == "owner"
      ? options
      : options.filter((option) => option.value !== "owner");
  const disabled = isSelf || loading || !canEdit || !verified;

  function handleChangeRole(selectedOption) {
    setRole(selectedOption.value);
    handleShowChangeRole()
  }

  async function onValidateChangeRole() {
    setLoading(true);
    try {
      const response = await putJSON(`/members/${membership.id}`, {
        membership: { role: role },
      });
      if (response?.status === "ok") {
        setPreviousRole(role)
        handleCloseChangeRole(false)
      }
    } catch (error) {
      setRole(previousRole);
      console.warn(`Error while updating member role: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  async function handleChangeDefaultResponder(defaultResponder) {
    const newValue = defaultResponder.value;

    setLoading(true);
    try {
      const response = await putJSON(`/members/${membership.id}`, {
        membership: { default_responder: newValue },
      });
      if (response?.status === "ok") {
        setChecked(defaultResponder.value);
      }
    } catch (error) {
      console.warn(`Error while updating member role: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  const translateRole = (currentRole) => {
    const translations = {
      owner: "Super admin",
      admin: "Admin",
      common: "Utilisateur",
      worker: "Intervenant",
      accountant: "Comptable",
    };
    return translations[currentRole];
  };
  return (
    <>
      <article
        className={` members-table-row members-table-row--6col members__table-row ${
          !verified ? "members__table-row_not_verified" : ""
        }`}
      >
        <div className="members-table-row__col members-table-row__col--start">
          <div className="members-user-info">
            {member?.avatar_url ? (
              <div>
                <img
                  src={member?.avatar_url}
                  className="avatar members-user-info__avatar"
                />
              </div>
            ) : (
              <div className="avatar members-user-info__avatar">
                {membership?.initials}
              </div>
            )}
            <span className="user-info__name">
              {" "}
              {member?.first_name} {member?.last_name}
              {isSelf ? (
                <i className="members-table-row__content"> (vous)</i>
              ) : !verified ? (
                <i className="members-table-row__content"> (invité)</i>
              ) : null}
            </span>
          </div>
        </div>
        <div className="members-table-row__col table-row__col--start">
          <span className="members-table-row__content">{member?.position}</span>
        </div>
        <div className="members-table-row__col members-table-row__col--start">
          <span className="members-table-row__content">{member?.email}</span>
        </div>
        <div className="members-table-row__col members-table-row__col--center">
          <span className="members-table-row__content">
            {member?.mobile_phone}
          </span>
        </div>
        <div className="members-table-row__col members-table-row__col--center">
          <span className="members-table-row__content">{member?.phone}</span>
        </div>
        <div className="members-table-row__col members-table-row__col--center">
          {""}
          <Checkbox
            membership={membership}
            checked={checked}
            onChange={handleShowDefaultResponder}
            disabled={!canEditDefaultResponder}
          />
        </div>
        <div
          className={`members-table-row__col members-table-row__col--center`}
        >
          {isSelf ? (
            <div>
              <span className="members-table-row__content">
                {" "}
                {translateRole(role)}{" "}
              </span>
            </div>
          ) : (
            <MemberListSelect
              value={verified ? value : null}
              menuBackgroundColor="#FBBB3E"
              onChange={handleChangeRole}
              options={
                currentUserRole === "admin"
                  ? options.filter((arr) => arr.value != "owner")
                  : options
              }
              placeholder="En attente"
              isDisabled={disabled}
            />
          )}
        </div>
        <div className="members-table-row__col members-table-row__col--center">
          {canDelete(member) ? (
            <a className="btn-icon" role="button" onClick={handleShow}>
              <span className="sr-only">Retirer ce collaborateur</span>
              <img src={deleteIcon} />
            </a>
          ) : (
            <span className="btn-icon">&nbsp;</span>
          )}
        </div>
      </article>
      <DefaultResponderModal
        show={showDefaultResponder}
        handleClose={handleCloseDefaultResponder}
        close={handleCloseDefaultResponder}
        membership={membership}
      />
      <DeleteModal
          show={show}
          handleClose={handleClose}
          membership={membership}
      />
      <ChangeRoleModal
        show={showChangeRole}
        handleClose={handleCloseChangeRole}
        onValidate={onValidateChangeRole}
        membership={membership}
      />
    </>
  );
}
