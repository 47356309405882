import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { postQuote } from '../../actions/index';
import "./quote_form.css";

class QuoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      amount_incl: '',
      amount_excl: '',
      expires_at: '',
      supports_at: '',
      electronic_signature: '',
      subscription: '',
      // file: React.createRef(),
      proposal_id: this.props.proposal
    };
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({ [event.target.name]: value });
   }

  handleFileChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
   }

  handleSubmit = (event) => {
    event.preventDefault();

    // const quote  = new FormData();
    // for(let name in this.state) {
    //   quote.append(name, this.state[name]);
    // }

    this.props.postQuote(this.state.proposal_id, this.state);

    this.setState({
      url: '',
      amount_incl: '',
      amount_excl: '',
      expires_at: '',
      supports_at: '',
      electronic_signature: '',
      subscription: '',
      // file: React.createRef(),
      proposal_id: this.props.proposal
    }); // Reset message input
    this.props.close();
  }

  render() {

      return (
          <div className="quote-form">
          <form onSubmit={this.handleSubmit} className="quote-form__form">
             <div className="basic-form">

                 <div className="form-group">
                    <label className="form-control-label">lien Sellsy</label>
                    <input className="form-control string" type="url" name="url" value={this.state.url} onChange={this.handleChange} />
                 </div>

                 {/*<div className="form-group">
                    <label className="form-control-label">Cocher cette case si vous souhaitez uniquement envoyer le mail Sellsy (signature éléctronique)</label>
                    <input className="form-control string" type="checkbox" name="electronic_signature" checked={this.state.electronic_signature} onChange={this.handleChange} />
                 </div>*/}

                 <div className="form-group checkbox-form-group">
                    <label className="form-control-label">Abonnement ?</label>
                    <input className="form-control string" type="checkbox" name="subscription" checked={this.state.subscription} onChange={this.handleChange} />
                 </div>

                 <div className="form-group">
                    <label className="form-control-label">Montant HT</label>
                    <input className="form-control decimal" type="number" name="amount_excl" value={this.state.amount_excl} onChange={this.handleChange} />
                 </div>

                 <div className="form-group">
                    <label className="form-control-label">Montant TTC</label>
                    <input className="form-control decimal" type="number" name="amount_incl" value={this.state.amount_incl} onChange={this.handleChange} />
                 </div>

                 <div className="form-group string">
                    <label className="form-control-label string">Date d'expiration</label>
                    <input className="form-control string datepicker-here" data-language="fr" autoComplete="off" name="expires_at" value={this.state.expires_at} onChange={this.handleChange} onBlur={this.handleChange} />
                </div>

                <div className="form-group string">
                    <label className="form-control-label string">Date d'intervention</label>
                    <input className="form-control string datepicker-here" data-language="fr" autoComplete="off" name="supports_at" value={this.state.supports_at} onChange={this.handleChange} onBlur={this.handleChange} />
                </div>

               {/* <div className="form-group file">
                  <label className="file">File</label>
                  <input className="form-control-file file" type="file" name="file" ref={this.state.file} onChange={this.handleFileChange} />
                </div>*/}

              </div>

              <div className="quote-form__buttons">
                <button type="submit" className="red-button">Enregistrer</button>
              </div>

            </form>
        </div>
      )
    }

  };


function mapStateToProps(state) {
  return {
    quote: state.selectedQuote
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ postQuote }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteForm);
