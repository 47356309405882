import "./members.css";
import React, { useState } from "react";
import { CustomSelect } from "../customSelect/custom_select";
import { RolesModal } from "../roles_modal/roles_modal";
import * as yup from "yup";
import { useFormik } from "formik";
import postJSON from "../../ajax/postJSON";
import { Spinner } from "react-bootstrap";
import { PartnerRolesModal } from "../roles_modal/roles_modal_partner";

const options = [
  { value: "owner", label: "Super admin" },
  { value: "admin", label: "Admin" },
  { value: "common", label: "Utilisateur" },
  { value: "worker", label: "Intervenant" },
  { value: "accountant", label: "Comptable" },
];

const schema = yup.object(
  {
    email: yup
      .string()
      .email("Doit être un email valide")
      .required("Est obligatoire"),
    first_name: yup.string().required("Est obligatoire"),
    last_name: yup.string().required("Est obligatoire"),
    phone: yup.string(),
    mobile_phone: yup.string().when("phone", {
      is: (phone) => !phone || phone.length === 0,
      then: yup.string().required("Est obligatoire"),
      otherwise: yup.string(),
    }),
    position: yup.string(),
    role: yup
      .string()
      .oneOf(options.map((o) => o.value))
      .required("Est obligatoire"),
  },
  [["phone", "mobile_phone"]]
);

export const MembreNew = (props) => {
  const [showClient, setShowClient] = useState(false);
  const [showPartner, setShowPartner] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);

  const {
    currentMembership: { side, role },
  } = props;
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      mobile_phone: "",
      position: "",
      role: "",
    },
    validationSchema: schema,
    async onSubmit(
      { email, first_name, last_name, phone, mobile_phone, position, role },
      { setFieldError }
    ) {
      const body = {
        membership: {
          role,
          user: {
            email,
            first_name: first_name,
            last_name: last_name,
            phone: phone,
            mobile_phone: mobile_phone,
            position,
          },
        },
      };
      setShowSpinner(true);
      const response = await postJSON("/members", body);
      if (response.status === "ok") {
        window.location.href = "/members";
      } else if (response.cause === "conflict") {
        setShowSpinner(false);
        setFieldError("email", "Est déjà présent dans l'équipe");
      } else {
        setShowSpinner(false);
        for (const [field, errors] of Object.entries(response.errors)) {
          setFieldError(field, errors.join(", "));
        }
      }
    },
  });

  const handleClose = () => setShowClient(false);
  const handleClosePartner = () => setShowPartner(false);

  const handleShow = () => setShowClient(true);
  const handleShowPartner = () => setShowPartner(true);
  return (
    <div>
      <form onSubmit={formik.handleSubmit} noValidate={true}>
        <table>
          <tbody>
            <tr>
              <td>
                <div className="form-part">
                  <CustomInput
                    label="Nom du membre"
                    name="last_name"
                    placeholder="Ex : Doe"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.last_name && formik.errors.last_name}
                  />
                </div>
              </td>
              <td>
                <div className="form-part padding-left">
                  <CustomInput
                    label="Prénom"
                    name="first_name"
                    placeholder="ex : John"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-part">
                  <CustomInput
                    label="Email"
                    name="email"
                    placeholder="ex : John.doe@mail.com"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && formik.errors.email}
                  />
                </div>
              </td>
              <td>
                <div className="form-part padding-left">
                  <CustomInput
                    label="Téléphone fixe"
                    name="phone"
                    type="tel"
                    placeholder="ex : 06 65 65 54 87"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && formik.errors.phone}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-part">
                  <CustomInput
                    label="Poste occupé"
                    name="position"
                    placeholder="ex : -"
                    value={formik.values.position}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.position && formik.errors.position}
                  />
                </div>
              </td>
              <td>
                <div className="form-part padding-left">
                  <CustomInput
                    label="Téléphone mobile"
                    name="mobile_phone"
                    type="tel"
                    placeholder="ex : 06 65 65 54 87"
                    value={formik.values.mobile_phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.mobile_phone && formik.errors.mobile_phone
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className="form-part padding-left">
                  <label className="labelText members__input-label">
                    Rôle du membre
                    <CustomSelect
                      menuBackgroundColor="#FBBB3E"
                      handleShow={
                        side == "provider" ? handleShowPartner : handleShow
                      }
                      onChange={formik.handleChange("role")}
                      options={
                        role === "admin"
                          ? options.filter((arr) => arr.value != "owner")
                          : options
                      }
                      placeholder="Choisir"
                      width="600"
                      height="48"
                    />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className="form-part">
                  {!showSpinner ? (
                    <div className="div_btn">
                      <a className="cancel_btn" href={`/members`}>
                        <center>Annuler</center>
                      </a>
                      &nbsp; &nbsp;
                      <input
                        className={classNames([
                          "form-submit-style",
                          "members__submit",
                          !formik.isValid && "invalid",
                        ])}
                        type="submit"
                        value="Ajouter"
                      />
                    </div>
                  ) : (
                    <div className="div_btn">
                      <Spinner animation="border" variant="warning" />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <RolesModal show={showClient} handleClose={handleClose} />
      <PartnerRolesModal show={showPartner} handleClose={handleClosePartner} />
    </div>
  );
};

const classNames = (classes) => classes.filter(Boolean).join(" ");

function CustomInput({ label, error, type = "text", ...inputProps }) {
  const labelClassName = classNames([
    "labelText",
    "members__input-label",
    Boolean(error) && "invalid",
  ]);
  const inputClassName = classNames([
    "members__input",
    Boolean(error) && "invalid",
  ]);
  return (
    <label className={labelClassName}>
      {label}
      <input className={inputClassName} type={type} {...inputProps} />
      {error && <span className="members__input-error">{error}</span>}
    </label>
  );
}
