import React from "react";

export const CustomButton = (props) => {
  const {
    name,
    type,
    value,
    width,
    height,
    backgroundColor,
    color,
    borderRadius,
    onClick,
    opacity,
    disabled,
  } = props;

  const styles = {
    btnContainer: {
      width: `${width}px`,
      height: `${height}px`,
      background:
        backgroundColor == "primary"
          ? "linear-gradient(87.28deg, #ED514E -16.88%, #FBBB3E 125.68%)"
          : "white",
      color: color,
      border: backgroundColor == "secondary" ? "1px solid #143456" : "0px",
      borderRadius: `${borderRadius}px`,
      margin: "20px 5px",
      opacity: opacity,
    },
  };

  function handleClick() {
    onClick("Valider");
  }
  return (
    <input
      style={styles.btnContainer}
      name={name}
      type={type}
      value={value}
      onClick={handleClick}
      disabled={disabled ?? false}
    />
  );
};
