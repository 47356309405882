// external modules
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import reduxPromise from 'redux-promise';

// Basic EvilMartian component ReactDomSelectors
// NB: store all imported selectors in rootDOMSelectors
import { reactRootDomSelectors as navSelectors } from "components/navbar/navbar";
import { reactRootDomSelectors as mainSearchSelectors } from "components/search_form/search_form";
import { reactRootDomSelectors as navSearchSelectors } from "components/navbar/navbar";
import { reactRootDomSelectors as caretakerNeedSelectors } from "components/caretaker_need/caretaker_need";
import { reactRootDomSelectors as clientNeedSelectors } from "components/client_need/client_need";
import { reactRootDomSelectors as providerNeedSelectors } from "components/provider_need/provider_need";
import { reactRootDomSelectors as dashboardSelectors } from "components/needs_list/needs_list";
import { reactRootDomSelectors as invoicesSelectors } from "components/invoices/invoices";
import { reactRootDomSelectors as notifListSelectors } from "components/notif_list/notif_list";
import { reactRootDomSelectors as membersSelectors } from "components/members/members";
import { reactRootDomSelectors as members_new_Selectors } from "components/members_new/members_new";
import { reactRootDomSelectors as participants_Selectors } from "components/participants_assign/participants_assign";
import { reactRootDomSelectors as memberSelection } from "components/member_selection/member_selection";
import { reactRootDomSelectors as participantsAllSelection } from "components/participants_list_all/participants_list_all";
import DocumentLibraryWidget from "containers/document_library_widget/document_library_widget";

const rootDOMSelectors =  {
  ...navSelectors,
  ...mainSearchSelectors,
  ...navSearchSelectors,
  ...caretakerNeedSelectors,
  ...clientNeedSelectors,
  ...dashboardSelectors,
  ...invoicesSelectors,
  ...notifListSelectors,
  ...membersSelectors,
  ...members_new_Selectors,
  ...participants_Selectors,
  ...memberSelection,
  ...participantsAllSelection,
}

// State and reducers
import chatsReducer from '../reducers/chats_reducer';
import messagesReducer from '../reducers/messages_reducer';
import notifsReducer from '../reducers/notifs_reducer';
import needReducer from '../reducers/need_reducer';
import proposalsReducer from '../reducers/proposals_reducer';
import answersReducer from '../reducers/answers_reducer';
import quotesReducer from '../reducers/quotes_reducer';
import firmsReducer from '../reducers/firms_reducer';
import stepsReducer from '../reducers/steps_reducer';
import userTokenReducer from '../reducers/user_reducer';
import attachmentsReducer from '../reducers/attachments_reducer';

import { ErrorBoundary } from "@appsignal/react";

const preloaderContainer = document.getElementById("js-need__need-root");
const preloaderMembersContainer = document.getElementById("js-members__members-root");
const firmsContainer = document.getElementById("caretaker-need");
const userTokenDomEl = document.getElementById("js-navbar-riing__user-token");
const userToken = userTokenDomEl ? userTokenDomEl.dataset.userToken : null;
const files = document.getElementById('document-library') ? JSON.parse(document.getElementById('document-library').dataset.documents) : []
const initialState = {
  userToken: userToken,
  chats: [],
  messages: [],
  notifs: [],
  need: preloaderContainer ? JSON.parse(preloaderContainer.dataset.need) : [],
  answers: preloaderContainer ? JSON.parse(preloaderContainer.dataset.answers) : [],
  members: [],
  quotes: [],
  firms: firmsContainer ? JSON.parse(firmsContainer.dataset.firms) : [],
  steps: [],
  attachments: files
};
const reducers = combineReducers({
  userToken: userTokenReducer,
  chats: chatsReducer,
  messages: messagesReducer,
  members: messagesReducer, // reducers incorrect
  notifs: notifsReducer,
  need: needReducer,
  proposals: proposalsReducer,
  answers: answersReducer,
  quotes: quotesReducer,
  firms: firmsReducer,
  steps: stepsReducer,
  attachments: attachmentsReducer
});

// Middlewares
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = composeEnhancers(applyMiddleware(reduxPromise, logger));
const store = createStore(reducers, initialState, middlewares);

import Appsignal from "@appsignal/javascript";

let appsignal = null

if(process.env.NODE_ENV == 'production') {
    appsignal = new Appsignal({
      key: "66968596-3933-445c-9f11-a82231fc510a"
  })
  console.log(process.env.NODE_ENV)
}

const Comp = ({children}) => {
    const content = <Provider store={store}> { children } </Provider>
    return (process.env.NODE_ENV == 'production'
        ? <ErrorBoundary instance={appsignal}> {content} </ErrorBoundary>
        : content)
}


document.addEventListener("DOMContentLoaded", () => {

  // Render Basic EvilMartian component React parts
  if (rootDOMSelectors) {
    for (const selector of Object.keys(rootDOMSelectors)) {
      const ComponentTag = rootDOMSelectors[selector].component;
      const componentProps = rootDOMSelectors[selector].props;
      const reactDomRoot = document.getElementById(selector.substring(1));
      const scope = reactDomRoot ? reactDomRoot.dataset.scope : undefined;
      const state = reactDomRoot ? reactDomRoot.dataset.state : undefined;
      const ratable = reactDomRoot ? reactDomRoot.dataset.ratable : undefined;
      document.querySelectorAll(selector).forEach(el => {
        const initialProps = Object.assign({}, el.dataset)
        for (const a of Object.keys(initialProps)) {
            try {
                const val = JSON.parse(initialProps[a])
                initialProps[a] = val
            } catch {}
        }

        ReactDOM.render(
          <Comp>
            <ComponentTag {...componentProps} {...initialProps} scope={scope} state={state} ratable={ratable} />
          </Comp>,
          el
        );
      })
      if (document.getElementById('document-library')) {
        ReactDOM.render(
          <Comp>
            <DocumentLibraryWidget />
          </Comp>,
          document.getElementById('document-library')
        )
      }
    }
  }

});
