import React, { Component } from 'react';
import "./flash.css";

class Flash extends Component {

  render() {
    const display = this.props.display
    const classes = `alert alert-dismissible fade show m-1 react-flash ${this.props.type}`
    return (
      <div className={classes} role="alert" style={{display: display}}>
        {this.props.message}
        <button type="button" className="close" onClick={this.props.close} >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    )
  }
}


export default Flash;
