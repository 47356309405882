import React, { Component } from "react";
import ChatList from "../../containers/chat_list/chat_list";
import MessageList from "../../containers/message_list/message_list";
import "./messenger.css";

class Messenger extends Component {
  render() {
    const chatId =
      this.props.match.params.chatId == "client"
        ? 16
        : this.props.match.params.chatId;
    const needId = this.props.match.params.needId;
    const { current_membership } = this.props
    return (
      <div className="messenger">
        <ChatList
          chatId={chatId}
          needId={needId}
          scope={this.props.scope}
          state={this.props.state}
        />
        <MessageList chatId={chatId} current_membership={current_membership} />
      </div>
    );
  }
}

export default Messenger;
