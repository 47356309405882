// https://react-bootstrap.github.io/components/overlays/#overlays
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import NeedCard from "../../components/need_card/need_card";
import NotifCounter from '../../containers/notif_counter/notif_counter';

import "./needs_list.css";

class NeedsList extends Component {

  constructor(props) {
      super(props);
      const filterProvider = document.getElementById("js-dashboard__dashboard-root").dataset.role == 'provider' ? ['pending', 'pushed', 'quoting', 'validated', 'paid', 'done'] : ['pending', 'pushed', 'quoting', 'validated', 'paid', 'done', 'refused', 'cancelled']
      this.state = {
        filter: ["subscription", "oneshot"],
        filterProvider: filterProvider,
        subscription: true,
        oneshot: true,
        refused: false,
        paid: true
      }
    }

  setFilters = () => {
    const elem = event.target
    if (["subscription", "oneshot"].includes(elem.name)) {
      let checkedFilters = []
      const filters = document.getElementById("needFilters").getElementsByTagName('input')
      for (const [key, value] of Object.entries(filters)) {
        if (value.checked) {
          checkedFilters.push(value.value)
        }
      }
      this.setState({ filter: checkedFilters, [elem.name]: elem.checked })
    } else {
      let checkedFilters = ['pending', 'pushed', 'quoting', 'validated', 'done']
      const filters = document.getElementById("needProvFilters").getElementsByTagName('input')
      for (const [key, value] of Object.entries(filters)) {
        if (value.checked) {
          checkedFilters.push(value.value)
        }
      }
      this.setState({ filterProvider: checkedFilters, [elem.name]: elem.checked })
    }
  }

  renderFilters = (filters) => {
    return (
      <div id="needFilters">
      <h5>Filtres</h5>
       {filters.map((key, index) => {
          const type = key.key
          return (
            <span key={index} className="form-check">
              <input
                name={key.key}
                id={key.key}
                type="checkbox"
                value={key.key}
                onChange={this.setFilters}
                checked={this.state[key.key]}
              />
              <label className="filter-label" htmlFor={key.key}>{key.value}</label>
            </span>
          )
        }
      )}
     </div>
     )
  }

  renderProviderFilters = (filters, role) => {
    if (role == 'provider') {
      return (
        <div id="needProvFilters">
        <h5>Filtres Prestataire</h5>
         {filters.map((key, index) => {
            const type = key.key
            return (
              <span key={index} className="form-check">
                <input
                  name={key.key}
                  id={key.key}
                  type="checkbox"
                  value={key.key}
                  onChange={this.setFilters}
                  checked={this.state[key.key]}
                />
                <label className="filter-label" htmlFor={key.key}>{key.value}</label>
              </span>
            )
          }
        )}
       </div>
       )
    }
  }

  renderContent = (filteredElements, filterStr) => {
    if (filteredElements.length == 0 || !filterStr.includes('oneshot')) {
      return <div className="innner-text">-</div>
    } else {
      return filteredElements
    }
  }

  renderSubContent = (filteredAboElements, filterStr) => {
    if (filteredAboElements.length == 0 || !filterStr.includes('subscription')) {
      return <div className="innner-text"></div>
    } else {
      return <><h4 className="abo">Mes Abonnements</h4>{filteredAboElements}</>
    }
  }


  render() {
    const userType = window.location.href.split('/')[3]
    const title = document.getElementById("js-dashboard__dashboard-root").dataset.title
    const role = document.getElementById("js-dashboard__dashboard-root").dataset.role
    const filterStr = this.state.filter
    const filterPrStr = this.state.filterProvider
    const needs = this.props.data

    const filteredElements = needs.filter(need => 'oneshot'
      .includes(need.subscription)).filter(need => filterPrStr
      .includes(need.state))
      .map(need =>
        { return <NeedCard key={need.id} need={need} role={role} /> }
      )

    const filteredAboElements = needs.filter(need => 'subscription'
      .includes(need.subscription)).filter(need => filterPrStr
      .includes(need.state))
      .map(need =>
        { return <NeedCard key={need.id} need={need} role={role} /> }
      )

    const filters = [{key: "subscription", value: "Abonnements"}, {key: "oneshot", value: "Ponctuelles"}]
    const providerFilters = [{key: "paid", value: "Remportées"}, {key: "refused", value: "Perdues"}]

    return (
      <div className="needs-list">
        <div className="filters needs-filters">
          { this.renderFilters(filters) }
          { this.renderProviderFilters(providerFilters, role) }
        </div>
        <div className="needs-list-inner">
          <h4>{title}</h4>
          { this.renderContent(filteredElements, filterStr) }
          { this.renderSubContent(filteredAboElements, filterStr) }
        </div>
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    notifs: state.notifs.filter(n => { return n.read_at == null && n.notifiable_type === 'Message' && n.action === 'received' })
  };
}

export default connect(mapStateToProps, {})(NeedsList);

