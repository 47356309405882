import NeedRouter from "../../components/need_router/need_router";
import StepsModal from "../../containers/steps_modal/steps_modal";
import "./client_need.css";

const reactRootDomSelectors = {
  "#js-need__need-root": { component: NeedRouter },
  "#js-need__steps-modal": { component: StepsModal }
};

export { reactRootDomSelectors };
