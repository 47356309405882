import React from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./roles_modal_partner.css";

const partnerData = [
  {
    id: 1,
    title: "Consulter une demande",
    roles: ["owner", "admin", "common", "worker"],
  },
  {
    id: 2,
    title: "Proposer un devis",
    roles: ["owner", "admin", "common"],
  },
  {
    id: 3,
    title: "Ajouter un collaborateur à ma Team",
    roles: ["owner", "admin"],
  },
  {
    id: 4,
    title: "Inviter un client hors plateforme Riing",
    roles: ["owner", "admin"],
  },
  {
    id: 5,
    title: "Intégrer et consulter une facture dans mon espace Riing",
    roles: ["owner", "admin", "accountant"],
  },
  {
    id: 6,
    title: "Modifier les informations de mon entreprise",
    roles: ["owner", "accountant"],
  },
  {
    id: 7,
    title: "Accès aux adresses et numéros de téléphones des clients",
    roles: ["owner", "admin", "common", "worker"],
  },
];

export const PartnerRolesModal = (props) => {
  const { show, handleClose } = props;

  return (
    <>
      <Modal
        id="rolesModal"
        className="roles-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            Récapitulatifs des droits
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="modal-table">
            <thead className="table_thead">
              <th>
                <div className="caseTableTitle">
                  <p> Droits </p>
                </div>
              </th>
              <th className="case_table_with_desc">
                <div>
                  {" "}
                  <center>
                    {" "}
                    <p className="title_with_description">Super Admin</p>
                    <div className="description_block">
                      <span className="role_description">
                        {" "}
                        <br />
                        Vous êtes le gestionnaire <br /> de <br /> l'entreprise
                      </span>
                    </div>
                  </center>
                </div>
              </th>
              <th className="case_table_with_desc">
                <div>
                  <center>
                    {" "}
                    <p className="title_with_description">Admin</p>
                    <div className="description_block">
                      <span className="role_description">
                        {" "}
                        <br />
                        Vous êtes autonomes et <br></br>avez besoin de certaines{" "}
                        <br></br>validations
                      </span>
                    </div>
                  </center>
                </div>
              </th>
              <th className="case_table_with_desc">
                <center>
                  {" "}
                  <p className="title_with_description">Utilisateur</p>
                  <div className="description_block">
                    <span className="role_description">
                      {" "}
                      <br />
                      Vous émettez <br /> des besoins et devez <br /> les faire
                      valider
                    </span>
                  </div>
                </center>
              </th>
              <th className="case_table_with_desc">
                <div>
                  <center>
                    {" "}
                    <p className="title_with_description">Intervenant</p>
                    <div className="description_block">
                      <span className="role_description">
                        {" "}
                        <br />
                        Vous êtes purement
                        <br /> opérationnel ou bien <br />
                        extérieur à l'entreprise
                      </span>
                    </div>
                  </center>
                </div>
              </th>
              <th className="case_table_with_desc">
                <div>
                  <center>
                    {" "}
                    <p className="title_with_description">Comptable</p>
                    <div className="description_block">
                      <span className="role_description">
                        {" "}
                        <br />
                        Vous devez avoir <br /> accès simplement aux <br />{" "}
                        factures
                      </span>
                    </div>
                  </center>
                </div>
              </th>
            </thead>
            <tbody>
              {partnerData.map((x) => {
                return (
                  <tr key={x.id}>
                    <td>
                      <div className="caseTableTitle">
                        <p className={x.id > 2 ? "tableRowText" : ""}>
                          {" "}
                          {x.title}{" "}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="caseTable">
                        <p>
                          {" "}
                          {x.roles.includes("owner") && (
                            <FontAwesomeIcon icon={faCheck} color="#163657" />
                          )}{" "}
                        </p>
                      </div>{" "}
                    </td>
                    <td>
                      <div className="caseTable">
                        <p>
                          {" "}
                          {x.roles.includes("admin") && (
                            <FontAwesomeIcon icon={faCheck} color="#163657" />
                          )}{" "}
                        </p>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="caseTable">
                        <p>
                          {" "}
                          {x.roles.includes("common") && (
                            <FontAwesomeIcon icon={faCheck} color="#163657" />
                          )}{" "}
                        </p>{" "}
                      </div>
                    </td>
                    <td>
                      <div className="caseTable">
                        <p>
                          {" "}
                          {x.roles.includes("worker") && (
                            <FontAwesomeIcon icon={faCheck} color="#163657" />
                          )}{" "}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="caseTable">
                        <p>
                          {" "}
                          {x.roles.includes("accountant") && (
                            <FontAwesomeIcon icon={faCheck} color="#163657" />
                          )}{" "}
                        </p>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};
