import React, { useMemo } from "react";
import Switch from "react-switch";
import checkIcon from "./static/Path.png";
import checkIconBlack from "./static/Path2.png";

export const ParticipantsTableRow = (props, ref) => {
  const {
    teamMember: {
      id,
      user: {
        first_name,
        last_name,
        position,
        email,
        phone,
        avatar_url,
        initials,
      },
      role,
      verified,
    },
    participantsMembershipIds,
    handleClick,
    user_id,
    currentMembership: { id: currentMembershipId, role: currentRole },
  } = props;
  const status = useMemo(() => {
    return !!participantsMembershipIds[id];
  }, [participantsMembershipIds, id]);

  const isSelf = id === currentMembershipId;

  const onTrigger = (value) => {
    handleClick(id, participantsMembershipIds[id], value);
  };

  const canAssign = () => {
    if (currentRole === "owner") return true;
    if (role === "owner") return false;
    if (currentRole === "admin" && role !== "owner") return true;
    return true;
  };

  const validRole = (currentRole) => {
    switch (currentRole) {
      case "owner":
        return "Super Admin";
      case "admin":
        return " Admin";
      case "common":
        return " Utilisateur";
      case "worker":
        return " Intervenant";
      case "accountant":
        return " Comptable";
      default:
        throw new Error(`role: ${currentRole}  is not managed`);
    }
  };

  return (
    <>
      <tr
        key={user_id}
        className={`table-row table-row--5col table-content ${
          verified == false ? "table-content-blur" : ""
        }`}
      >
        <td className="table-row__col table-row__col--start">
          <div className="user-info">
            <div className="user-info__visibility-div"></div>
            {avatar_url ? (
              <>
                <img
                  src={avatar_url}
                  className={`avatar ${status && "saturate"}`}
                />
                {status && <img src={checkIcon} className="child1" />}
              </>
            ) : (
              <>
                <div className={`avatar ${status && "saturate"}`}>
                  {initials}
                </div>
                {status && <img src={checkIconBlack} className="child2" />}
              </>
            )}
            <span className="user-info__name">
              {" "}
              {first_name} {last_name}{" "}
              {isSelf ? (
                <i> (moi)</i>
              ) : !verified ? (
                <i> (en attente) </i>
              ) : null}
            </span>
          </div>

          <span className="table-row__content"></span>
        </td>
        <td className="table-row__col table-row__col--start">
          <span className="table-row__content">{position}</span>
        </td>
        <td className="table-row__col table-row__col--start">
          <span className="table-row__content">{email}</span>
        </td>
        <td className="table-row__col table-row__col--start">
          <span className="table-row__content">{phone}</span>
        </td>
        <td
          className="table-row__col table-row__col--center"
          style={styles.colRole}
        >
          <span className="table-row__instructions"> {validRole(role)} </span>
        </td>
        <td className="table-row__col table-row__col--end">
          <a href="#" className="table-row__col__edit">
            {canAssign() && (
              <Switch
                onChange={(value) => onTrigger(value)}
                checked={status}
                checkedIcon={false}
                uncheckedIcon={false}
                offColor="#F0F4F6"
                onColor="#F48945"
                height={20}
                width={34}
                offHandleColor="#999CA8"
              />
            )}
          </a>
        </td>
      </tr>
      <hr className="separator" />
    </>
  );
};

const styles = {
  colRole: {
    minWidth: "10%",
    backgroundColor: "#F0F4F6",
    position: "relative",
    alignItem: "center",
  },
  colRoleSpan: {
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "40px",
    color: "#143456",
    fontSize: "14px",
    fontWeight: "200",
  },
};
