import React, { Component } from 'react';
import ValidateQuote from '../../components/validate_quote/validate_quote'
import "./quote.css";

class Quote extends Component {


  renderLink = () => {
    if (this.props.quote.state == 'pushed') {
      let path = `/caretaker/needs/${this.props.needId}/quotes/${this.props.quote.id}?state=created`
      return(
          <a href={path} data-method="put" className="">Retirer ce devis</a>
        )
    }
    else if (this.props.quote.state == 'refused') {
      return(
        <p className="quote__actions__blocked">Devis refusé</p>
      )
    }
    else if (this.props.quote.state == 'cancelled') {
      return(
        <p className="quote__actions__blocked">Devis annulé</p>
      )
    }
    else if (this.props.quote.state == 'selected') {
      return(
        <p className="quote__actions__blocked">Devis sélectionné</p>
      )
    }
    else {
      let path = `/caretaker/needs/${this.props.needId}/quotes/${this.props.quote.id}?state=pushed`
      return(
        <a href={path} data-method="put" className="red-button">Proposer ce devis</a>
      )
    }
  }


  renderActions = () => {
    if (this.props.scope == 'caretaker') {
    const cancelledPath = `/caretaker/needs/${this.props.needId}/quotes/${this.props.quote.id}?state=cancelled`
    const validatePath = `/caretaker/needs/${this.props.needId}/quotes/${this.props.quote.id}?state=selected`
    return (
       <div className="quote__actions">
          {this.renderLink()}
          <a href="#" className="quote__actions__more dropdown-toggle" id='stateDropdown' data-toggle="dropdown" aria-haspopup='true' aria-expanded='false'></a>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="stateDropdown">
            <a href={this.props.quote.url} target="_blank" className="dropdown-item">Voir le devis</a>
            <a href={validatePath} data-method="put" className="dropdown-item">Valider</a>
            <a href={cancelledPath} data-method="put" className="dropdown-item">Annuler</a>
          </div>
        </div>
      )

      } else {
      if (this.props.quote.state == 'selected') {
        return (
          <div className="quote__actions">
            <a href={this.props.quote.url} target="_blank" className="black-button">Voir le devis</a>
          </div>
          )
      } else {
        const refusedPath = `/client/needs/${this.props.needId}/quotes/${this.props.quote.id}?state=refused`
          return (
           <div className="quote__actions">
              <a href={refusedPath} data-method="put">Refuser le devis</a>
              <a href={this.props.quote.url} target="_blank" className="black-button">Voir le devis</a>

              <ValidateQuote quote={this.props.quote} needId={this.props.needId} />
            </div>
          )
      }
    }
  }

  renderDate = () => {
    if (this.props.quote.subscription == true) {
      return ('Abonnement')
    }
    else if (this.props.quote.supports_at) {
      return (
          new Date(this.props.quote.supports_at).toLocaleDateString()
        )
    } else {
      return ('n/a')
    }
  }

  render() {
    const classes = `quote ${this.props.quote.state}`;
      return (
        <div className={classes}>
          <div className="quote__price">
            <span className="quote__col-title">Prix(HT)</span>
            <span className="quote__price__amount">{this.props.quote.amount_excl}€</span>
          </div>
          <div className="quote__date">
            <span className="quote__col-title">Intervention</span>
            <span className="quote__date__intervention-date">{this.renderDate()}</span>
          </div>
          {this.renderActions()}
        </div>
      )
    }

  };

export default Quote;



