import React, { Component } from 'react';
import ProposalRefs from '../../components/proposal_refs/proposal_refs';
import ProviderProfile from '../../components/provider_profile/provider_profile';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./provider_short_profile.css";

class ProviderShortProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  close = () =>{
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }

  return_cgv = (cgv) => {
    if (cgv != '') {
      return (
          <a href={this.props.proposal.cgv} target="_blank" className="blue-button">Voir CGU</a>
        )
    }
  }

  render() {
    const rateClass = `provider-short-profile__rating star-${this.props.proposal.rating}`
    return (
      <div className="provider-short-profile block shadow">
        <div className="provider-short-profile__entete">
          <h2>{this.props.proposal.provider_name}</h2>
          <Button className="black-button provider-short-profile__entete__button" onClick={this.open}>
            Voir le profil
          </Button>

          <Modal id="providerModal" className="provider-modal" show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>
            <h2>
              {this.props.proposal.provider_name}
            </h2>
             {this.return_cgv(this.props.proposal.cgv)}
            </Modal.Title>
          </Modal.Header>
            <Modal.Body>
                <ProviderProfile proposal={this.props.proposal} />
            </Modal.Body>
          </Modal>
        </div>
        <div className={rateClass}>
            <i className="star"></i>
            <i className="star"></i>
            <i className="star"></i>
            <i className="star"></i>
            <i className="star"></i>
          </div>
        <ProposalRefs proposal={this.props.proposal}/>
      </div>
    );
  }
};

export default ProviderShortProfile;



