import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { fetchSteps } from '../../actions';
import Step from '../../components/step/step';
import "./steps_modal.css";

class StepsModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  componentWillMount() {
    this.fetchSteps();
  }

  fetchSteps = () => {
    this.props.fetchSteps(this.props.scope);
  }

  close = () =>{
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }

  render () {
    return (
      <>
        <Button className="red-button need-steps" onClick={this.open}>
          ?
        </Button>

        <Modal id="stepsModal" show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Récapitulatif de la mission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="need-steps">
              <div className="need-steps__content" ref={(list) => { this.list = list; }}>
                {
                  this.props.steps.map((step) => {
                    return <Step key={step.id} step={step} />;
                  })
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    steps: state.steps
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchSteps }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StepsModal);
