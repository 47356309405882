import React, { Component } from 'react';
import ChatList from '../../containers/chat_list/chat_list';
import RequalifiedNeed from '../../containers/requalified_need/requalified_need';
import NeedSteps from '../../containers/need_steps/need_steps';
import ProposalList from '../../containers/proposal_list/proposal_list';
import "./need_validated_view.css";

class NeedValidatedView extends Component {

  rateBlock = (needId, scope, ratable) => {
    const ratePath = `/${scope}/needs/${needId}/ratings/new`
    if (ratable == "true") {
      return (
          <div className='block shadow need-validated-view__rate-block'>
            <a href={ratePath} className="red-button">Evaluer la prestation</a>
          </div>
        )
    }
    else {
      return (
          <div className='block shadow need-validated-view__rate-block'>
            Vous avez déjà noté cette prestation ou sa période de notation est expirée
          </div>
        )
    }
  }

  renderQuote = (needId) => {
    if (this.props.scope == "client") {
      return <ProposalList needId={needId} />
    }
  }

  render() {
    const needId = this.props.match.params.needId;
    return (
      <div className="need-validated-view">
        <ChatList chatId='null' needId={needId} scope={this.props.scope} state={this.props.state} />
        <RequalifiedNeed needId={needId} scope={this.props.scope} />
        <NeedSteps needId={needId} />
        {this.rateBlock(needId, this.props.scope, this.props.ratable)}
        {this.renderQuote(needId)}
      </div>
    )
  }

}

export default NeedValidatedView;
