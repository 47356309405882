import React, { Component } from 'react';
import "./drop_zone_lib.css";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'


class DropZoneLib extends Component {

  destroyAttachment = (filename) => {
    const url = "/api/v1/attachments/detach";
    const body = { filename };
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      .content.value;
    const promise = fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
  }

  getUploadParams = ({ file, meta }) => {
    const body = new FormData()
    body.append('file', file)
    body.append('importId', meta.id)
    body.append('content_type', this.props.type)
    body.append('attached_id', this.props.recipient)
    body.append('attached_type', "Need")
    return { url: '/api/v1/attachments', body }
 }

  handleChangeStatus = ({ meta }, status) => {
    if (status == 'removed'){
      this.destroyAttachment(meta.id)
    }
  }

  handleSubmit = () => {
    this.props.submitContent();
  }

  render() {

    return (
      <Dropzone
        getUploadParams={this.getUploadParams}
        onChangeStatus={this.handleChangeStatus}
        styles={{ dropzone: { minHeight: 200, maxHeight: 600 } }}
        canRestart={false}
        onSubmit={this.handleSubmit}
        inputContent={
          <>
            <span className='gradiant-orange-button'>Ajouter un document</span>
            <span className="subtitle">.jpg, .png, .pdf, .xlsx, .docx</span>
          </>
        }
        inputWithFilesContent="Ajouter un document"
        submitButtonContent="Valider"
      />
    )
  }
}



export default DropZoneLib;

