import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CustomButton } from "../custom_button/custom_button";
import "./participants_update_modal.css";
import putJSON from "../../ajax/putJSON";

export const ParticipantsUpdateModal = (props) => {
  const {
    show,
    participation: selectedParticipation,
    currentMembership: { id },
    handleClose,
  } = props;
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClick = async () => {
    const newInstruction = inputValue;

    // setLoading(true);
    try {
      const response = await putJSON(
        `/participations/${selectedParticipation.id}`,
        {
          participation: { instructions: newInstruction },
        }
      );
      if (response?.status === "ok") {
        window.location.reload(false);
      }
    } catch (error) {
      console.warn(`Error while updating participation`);
    } finally {
      // setLoading(false);
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} className="participants-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {show && id != selectedParticipation?.membership_id && (
            <div>
              <p style={Styles.textParticipants}>
                {" "}
                Vous pouvez modifier les instructions particulières <br></br>{" "}
                concernant&nbsp;
                {selectedParticipation?.membership?.user?.first_name}{" "}
                {selectedParticipation?.membership?.user?.last_name}
                <br />
              </p>
              <center>
                <br />
                <br />
                <textarea
                  placeholder="Ex: absente tous les jeudi et disponible le vendredi matin"
                  onChange={handleChange}
                >
                  {selectedParticipation?.instructions}
                </textarea>
                <br />
                <br />
                <CustomButton
                  name="valider"
                  type="button"
                  value="Valider"
                  width="100"
                  height="48"
                  color="white"
                  backgroundColor="primary"
                  borderColor="orange"
                  borderRadius="56"
                  onClick={handleClick}
                />
              </center>
            </div>
          )}
          {show && id == selectedParticipation?.membership_id && (
            <div>
              <p style={Styles.textParticipants}>
                {" "}
                Vous pouvez modifier les instructions particulières <br></br>{" "}
                vous concernant
                <br />
              </p>
              <center>
                <br />
                <br />
                <textarea
                  placeholder="Ex: absente tous les jeudi et disponible le vendredi matin"
                  onChange={handleChange}
                >
                  {selectedParticipation?.instructions}
                </textarea>
                <br />
                <br />
                <CustomButton
                  name="valider"
                  type="button"
                  value="Valider"
                  width="100"
                  height="48"
                  color="white"
                  backgroundColor="primary"
                  borderColor="orange"
                  borderRadius="56"
                  onClick={handleClick}
                />
              </center>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const Styles = {
  textParticipants: {
    color: "#143456",
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "35px",
  },
  spanParticipants: {
    color: "#143456",
    fontWeight: "400",
    fontSize: "13px",
    textAlign: "center",
    verticalAlign: "middle",
  },
};
