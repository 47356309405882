import React, { Component } from "react";
import ChatList from "../../containers/chat_list/chat_list";
import Proposal_list from "../../containers/proposal_list/proposal_list";
import RequalifiedNeed from "../../containers/requalified_need/requalified_need";
import "./need_brief_view.css";

class NeedBriefView extends Component {
  showMessage() {
    const states = ["validated", "paid", "refused", "cancelled"];
    return states.includes(this.props.state);
  }

  render() {
    const needId = this.props.match.params.needId;
    const {  scope } = this.props
    return (
      <div className="need-brief-view">
        <ChatList
          chatId="null"
          needId={needId}
          scope={this.props.scope}
          state={this.props.state}
        />
        {!this.showMessage() && (
          <Proposal_list
            needId={needId}
            scope={this.props.scope}
            title="Dans l'attente de votre devis à envoyer au concierge dans ''message au concierge'' ou par mail à concierge@riing.fr"
          />
        )}
        <RequalifiedNeed needId={needId} scope={this.props.scope} />
      </div>
    );
  }
}

export default NeedBriefView;
