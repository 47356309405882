import { FETCH_NOTIFS, MARK_AS_READ, NOTIFS_RECEIVED } from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_NOTIFS: {
      return action.payload;
    }
    case MARK_AS_READ: {
      if (action.payload.ids.lengths === 0) {
        return state;
      }
      const copiedState = state.slice(0);
      return copiedState.filter(n => !action.payload.ids.includes(n.id));
    }
    case NOTIFS_RECEIVED: {
      // NB: Probalby a more powerful way of map / sort
      // const prevStateIds = JSON.stringify(Object.keys(state).map(k =>  state[k].id).sort());
      // const newStateIds = JSON.stringify(Object.keys(action.payload).map(k =>  action.payload[k].id).sort());
      // if (prevStateIds === newStateIds) {
      //   return state
      // } else {
      return action.payload;
      // }
    }
    default:
      return state;
  }
}
