import React from "react";
import { useCallback } from "react";
import IconCheck from "./IconCheck";

export function Checkbox({ checked, disabled, onChange }) {
  const toggle = useCallback(() => {
    handleCheck();
  }, []);

  const handleCheck = useCallback(async () => {
    onChange();
  }, []);

  const Styles = {
    button: {
      background: "white",
      border: !checked ? "2px solid #D1D8DB" : "3px solid #41E895",
      color: "#9B9B9B",
      cursor: "pointer",
      justifyContent: "center",
      width: "15px",
      height: "15px",
      opacity: disabled ? 0.5 : 1,
    },

    check: {
      justifyContent: "center",
      height: "15px",
      lineHeight: "15px",
      verticalAlign: "middle",
    },
  };

  return (
    <div style={Styles.button} onClick={disabled ? null : toggle}>
      <div style={Styles.check}>{checked && <IconCheck />}</div>
    </div>
  );
}
