import React, { useMemo, useState } from "react";
import { ClientConnexionSelect } from "../client_connexion_select/client_connexion_select";
import { CustomButton } from "../custom_button/custom_button";
import "./client_connexion.css";
import some from "lodash/some";
import postJSON from "../../ajax/postJSON";
import { PartnerModal } from "../partner_modal/partner_modal";
import { ClientModal } from "../client_modal/client_modal";

const Riing = require("./static/Riing.png");
const RiingLogo = require("./static/riingLogo.png");

export const ClientConnexion = (props) => {
  const { memberships } = props;

  const getInitialSide = () => {
    if (memberships.some((e) => e.side == "provider")) return "provider";
    return "client";
  };

  const [side, setSide] = useState(getInitialSide());
  const [showPartner, setShowPartner] = useState(false);
  const [showClient, setShowClient] = useState(false);

  const membershipsFiltered = useMemo(() => {
    return memberships.filter((m) => m.side === side);
  }, [memberships, side]);

  const hasSide = (side) => some(memberships, (m) => m.side === side);

  const options = membershipsFiltered.map(({ id, firm_name, firm_logo }) => ({
    label: (
      <>
        {" "}
        <img src={firm_logo.record.logo} className="logo_client_connexion" />
        {firm_name}
      </>
    ),
    value: id,
  }));

  const handleChange = async (e) => {
    await postJSON("/members/current", { membership_id: e }).then(
      ({ status }) => {
        if (status === "ok") {
          window.location.href = "/";
        }
      }
    );
  };

  const handleCloseClient = () => {
    setShowClient(false);
  };
  const handleShowClient = () => {
    if (!hasSide("client")) {
      setShowClient(true);
    } else {
      setSide("client");
    }
  };

  const handleClosePartner = () => {
    setShowPartner(false);
  };
  const handleShowPartner = () => {
    if (!hasSide("provider")) {
      setShowPartner(true);
    } else {
      setSide("provider");
    }
  };
  return (
    <form>
      <div className="container_client_connexion">
        <PartnerModal show={showPartner} handleClose={handleClosePartner} />
        <ClientModal show={showClient} handleClose={handleCloseClient} />
        <div className="box1">
          <img src={Riing} />
        </div>
        <div className="box2">
          <img src={RiingLogo} className="riingLogo" />
          <div className="type-client-btns">
            <CustomButton
              name="client"
              type="button"
              value="Client"
              width="125"
              height="48"
              color="white"
              backgroundColor="primary"
              borderRadius="56"
              onClick={handleShowClient}
              opacity={side === "client" ? 1 : 0.3}
            />
            <CustomButton
              name="partenaire"
              type="button"
              value="Partenaire"
              width="125"
              height="48"
              color="white"
              backgroundColor="primary"
              borderRadius="56"
              onClick={handleShowPartner}
              opacity={side === "provider" ? 1 : 0.3}
            />
          </div>
          <div className="selectDropdown">
            <ClientConnexionSelect
              menuBackgroundColor="#FBBB3E"
              onChange={handleChange}
              options={options}
              placeholder="Qui êtes-vous aujourd’hui ?"
              width="300"
              height="52"
            />
          </div>
          <a
            className="dropdown-item"
            rel="nofollow"
            data-method="delete"
            href="/users/sign_out"
          >
            Déconnexion
          </a>
        </div>
      </div>
    </form>
  );
};
