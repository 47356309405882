import React, { Component } from "react";
import Rating from "../../components/rating/rating";
import "./provider_profile.css";

class ProviderProfile extends Component {
  render() {
    return (
      <div className="provider-profile block">
        <p className="alert-text alert alert-info alert-dismissible fade show m-1">
          Attention : Le site étant en phase bêta-test, les informations
          ci-dessous ne sont pas encore représentatives de la qualité de nos
          prestataires !
        </p>
        <p className="provider-profile__description">
          {this.props.proposal.provider_description}
        </p>
        <h4>Recommandé par {this.props.proposal.client_count} client(s) :</h4>
        <div className="provider-profile__refs">
          {this.props.proposal.clients.map((arr) => {
            const client = arr[0];
            if (typeof client != "undefined") {
              const style = { backgroundImage: `url(${client.logo})` };
              return (
                <div
                  className="proposal-refs__client-refs__logos__client-logo"
                  style={style}
                  key={client.id}
                ></div>
              );
            }
          })}
        </div>
        <h4>Services commercialisés</h4>
        <div className="provider-profile__skill-list">
          {this.props.proposal.provider_skills.map((skill) => {
            return (
              <div
                className="tag"
                key={this.props.proposal.provider_skills.indexOf(skill)}
              >
                {skill}
              </div>
            );
          })}
        </div>

        <h4>Chiffres clés</h4>
        <div className="provider-profile__key-numbers">
          <div className="provider-profile__key-numbers__data">
            <div className="rounded-number">
              {this.props.proposal.mission_count}
            </div>
            <span className="title-bold">Missions</span>
          </div>
          <div className="provider-profile__key-numbers__data">
            <div className="rounded-number">
              {this.props.proposal.client_count}
            </div>
            <span className="title-bold">Clients</span>
          </div>
          <div className="provider-profile__key-numbers__data">
            <div className="rounded-number">
              {this.props.proposal.target_size}
            </div>
            <span className="title-bold">Taille des entreprises ciblées</span>
          </div>
          <div className="provider-profile__key-numbers__data">
            <div className="rounded-number">
              {this.props.proposal.recommendation_count}
            </div>
            <span className="title-bold">Recommandations</span>
          </div>
        </div>

        <h4>Avis et notations</h4>

        {this.props.proposal.ratings.map((rating) => {
          return <Rating rating={rating} key={rating.id} />;
        })}
      </div>
    );
  }
}

export default ProviderProfile;
