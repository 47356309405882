import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchSteps } from '../../actions';
import Step from '../../components/step/step';
import "./need_steps.css";

class NeedSteps extends Component {
  componentWillMount() {
    this.fetchSteps();
  }

  fetchSteps = () => {
    this.props.fetchSteps(this.props.needId);
  }

  render () {
    return (
      <div className="need-steps block shadow">
        <h3>Récapitulatif de la mission</h3>
        <div className="need-steps__content" ref={(list) => { this.list = list; }}>
          {
            this.props.steps.map((step) => {
              return <Step key={step.id} step={step} />;
            })
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    steps: state.steps
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchSteps }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NeedSteps);
