import React, { useState } from "react";
import putJSON from "../../ajax/putJSON";
import { ParticipantsIconVisibility } from "../participants_visible_icon/participants_visible_invisible_icon";
import editIcon from "./static/Edit.png";

export const ParticipantsAllTableRow = (props) => {
  const {
    participant: {
      id,
      instructions,
      visible,
      membership: {
        user: {
          avatar_url,
          last_name,
          first_name,
          email,
          position,
          phone,
          mobile_phone,
          initials,
        },
      },
    },
    side,
    currentMembership,
    handleClick,
  } = props;
  const [checked, setChecked] = useState(visible);
  const onTrigger = (value) => {
    handleClick(id);
  };

  const showPencil = () => {
    if (currentMembership.side === "caretaker") return true;
    if (side === currentMembership?.side) return true;
    return false;
  };

  const handleChange = async () => {
    try {
      const response = await putJSON(`/participations/${id}`, {
        participation: { visible: !checked },
      });
      if (response?.status === "ok") {
        window.location.reload(false);
        setChecked(!checked);
      }
    } catch (error) {
      console.warn(`Error while updating participation`);
    } finally {
      // setLoading(false);
    }
  };
  return (
    <article className="table-row table-row--6col">
      <div className="table-row__col">
        <div className="user-info">
          <div className="user-info__visibility-div">
            {currentMembership.side === "caretaker" && (
              <ParticipantsIconVisibility
                className="user-info__visibility"
                visible={checked}
                handleChange={handleChange}
              />
            )}
            {currentMembership?.side === "client" &&
              side === currentMembership?.side && (
                <ParticipantsIconVisibility
                  className="user-info__visibility"
                  visible={checked}
                  handleChange={handleChange}
                />
              )}
            {currentMembership?.side === "provider" &&
              side === currentMembership?.side && (
                <ParticipantsIconVisibility
                  className="user-info__visibility"
                  visible={checked}
                  handleChange={handleChange}
                />
              )}
          </div>
          {avatar_url ? (
            <div>
              <img src={avatar_url} className="avatar user-info__avatar" />
            </div>
          ) : (
            <div className="avatar hidden-mobile user-info__avatar">
              {initials}
            </div>
          )}
          <span className="user-info__name">
            {" "}
            {first_name} {last_name}
          </span>
        </div>

        <span className="table-row__content"></span>
      </div>
      <div className="table-row__col table-row__col--start">
        <span className="table-row__content">{position}</span>
      </div>
      <div className="table-row__col table-row__col--start">
        <span className="table-row__content">
          {currentMembership?.side === "caretaker" || currentMembership?.role === "accountant" && email}
        </span>
      </div>
      <div className="table-row__col table-row__col--start">
        <span className="table-row__content">{phone}</span>
      </div>
      <div className="table-row__col table-row__col--start">
        <span className="table-row__content">{mobile_phone}</span>
      </div>
      <div className="table-row__col table-row__col--start">
        <span className="table-row__instructions">{instructions}</span>
      </div>
      <div className="table-row__col table-row__col--end">
        <a href="#" className="table-row__col__edit">
          {showPencil() && (
            <img
              src={editIcon}
              alt="Edit user info"
              onClick={(value) => onTrigger(value)}
            />
          )}
        </a>
      </div>
    </article>
  );
};

const styles = {
  tableContent: {},
  colInstructions: {
    minWidth: "15%",
    maxWidth: "15%",
    height: "70px",
    flexWrap: "wrap",
    color: "#163657",
    overflow: "auto",
    overflowY: "auto",
    overflowX: "hidden",
  },
  colRoleSpan: {
    color: "#143456",
    fontSize: "14px",
    fontWeight: "300",
  },
  colName: {
    minWidth: "15%",
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "row",
  },
  colFunction: {
    minWidth: "20%",
  },
  colEmail: {
    minWidth: "20%",
  },
  colTel: {
    minWidth: "10%",
  },
  colAction: {
    minWidth: "10%",
    textAlign: "center",
  },
  intialCircle: {
    display: "inline-block",
    height: "45px",
    width: "45px",
    backgroundColor: "#ebb95d",
    borderRadius: "22px",
    marginRight: "10px",
  },
  initialText: {
    lineHeight: "45px",
    verticalAlign: "middle",
    color: "white",
    fontWeight: "500",
  },
  textContent: {
    color: "#143456",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "40px",
    verticalAlign: "middle",
    textAlign: "center",
    paddingBottom: "15px",
    marginLeft: "10px",
  },
  separator: {},
};
