import React, { Component } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import ServicePriceModal from "../../components/service_price_modal/service_price_modal";
import ContactModal from "../../components/contact_modal/contact_modal";
import "./need_actions.css";
import { DeleteNeedModal } from "../delete_need_modal/delete_need_modal";

class NeedActions extends Component {
  setArrow = (e) => {
    e.target.parentElement.parentElement.classList.add("selected");
  };

  state= {
    show: false
  }

  handleClose = () => this.setState({
    show: false
  })
  handleShow = () => this.setState({
    show: true
  })

  render() {
    if (this.props.role != "provider") {
      return (
        <div className="need-actions">
          <OverlayTrigger
            trigger="click"
            placement="left"
            containerPadding={20}
            overlay={
              <Popover id="popover-basic" className="need-actions-popin">
                <Popover.Content>
                  <ul>
                    <li>
                      <a href={this.props.need.show_link}>
                        <div className="icon edit"></div>Modifier
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={this.handleShow}
                      >
                        <div className="icon delete"></div>Supprimer
                      </a>
                    </li>
                    <li>
                      <a
                        data-confirm="Êtes-vous sur de vouloir renouveller ce besoin ? Cette action génèrera un nouveau besoin"
                        data-method="put"
                        href={this.props.need.renew_link}
                      >
                        <div className="icon dupli"></div>Dupliquer
                      </a>
                    </li>
                    <ServicePriceModal
                      price_range={this.props.need.price_range}
                    />
                    <ContactModal
                      need={this.props.need}
                      role={this.props.role}
                    />
                    <DeleteNeedModal
                      show={this.state.show}
                      handleClose={this.handleClose}
                      deleteLink={this.props.need.cancel_link}
                    />
                  </ul>
                </Popover.Content>
              </Popover>
            }
            rootClose
          >
            <a className="more-button" onClick={this.setArrow}></a>
          </OverlayTrigger>
        </div>
      );
    } else {
      return (
        <div className="need-actions">
          <OverlayTrigger
            trigger="click"
            placement="left"
            containerPadding={20}
            overlay={
              <Popover id="popover-basic" className="need-actions-popin">
                <Popover.Content>
                  <ul>
                    <li>
                      <a href={this.props.need.show_link}>
                        <div className="icon edit"></div>Voir
                      </a>
                    </li>
                    <ContactModal
                      need={this.props.need}
                      role={this.props.role}
                    />
                  </ul>
                </Popover.Content>
              </Popover>
            }
            rootClose
          >
            <a className="more-button" onClick={this.setArrow}></a>
          </OverlayTrigger>
        </div>
      );
    }
  }
}

export default NeedActions;
