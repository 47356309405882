import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ChatList from '../../containers/chat_list/chat_list';
import MessageList from '../../containers/message_list/message_list';
import ProviderShortProfile from '../../components/provider_short_profile/provider_short_profile';
import ProviderProposal from '../../containers/provider_proposal/provider_proposal';
import { fetchProposals } from '../../actions/index';

import "./need_provider_view.css";

class NeedProviderView extends Component {

  componentWillMount() {
    this.props.fetchProposals(this.props.match.params.needId, false, this.props.match.params.chatId)
  }

  componentWillReceiveProps(nextProps) {
  if (this.props.match.params.chatId !== nextProps.match.params.chatId) {
    this.props.fetchProposals(this.props.match.params.needId, false, nextProps.match.params.chatId)
  }
}

  render() {
    const chatId = this.props.match.params.chatId
    const needId = this.props.match.params.needId
    return (
      <div className="need-provider-view">
        <ChatList chatId={chatId} needId={needId} scope={this.props.scope} state={this.props.state} />
        {
          this.props.proposals.map((proposal) => {
            return (
              <ProviderShortProfile chatId={chatId} needId={needId} key={proposal.id} proposal={proposal} />
              )
          })
        }
        {
          this.props.proposals.map((proposal) => {
            return (
              <ProviderProposal chatId={chatId} needId={needId} key={proposal.id} proposal={proposal} />
              )
          })
        }
        <div className="need-provider-view__right-col block shadow">
          <div className="message-list__title">
            <h4>Messages à #{chatId}</h4>
          </div>
          <MessageList chatId={chatId} />
        </div>
      </div>
    )
  }

}



function mapStateToProps(state) {
  return {
    proposals: state.proposals,
    need: state.need
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchProposals }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NeedProviderView);
