import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Flash from '../../components/flash/flash';
import { fetchAnswers, postAnswers, requalifyNeed, fetchNeed } from '../../actions/index';
import "./qualify_form.css";

class QualifyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: this.props.answers,
      need: this.props.need,
      showAlert: 'none',
      alertType: '',
      alertMessage: ''
    };
  }

  close = () =>{
    this.setState({ showAlert: 'none' });
  }

  open = () => {
    this.setState({ showAlert: 'block' });
  }

  componentDidMount() {
    this.props.fetchAnswers(this.props.needId);
    this.props.fetchNeed(this.props.needId);
  }

  componentWillUnmount() {
    this.props.fetchAnswers(this.props.needId);
    this.props.fetchNeed(this.props.needId);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const shouldWarn = document.getElementById('warn_providers').checked
    const transferAddress = document.getElementById('transfer_address').checked

    this.props.postAnswers(this.props.needId, this.state.answers, shouldWarn);
    this.props.requalifyNeed(this.props.needId, this.state.need.description, this.state.need.feedback, transferAddress
        ).then(res => {
             this.setState({ need: res.payload, showAlert: 'block', alertType: 'alert-info', alertMessage: 'Besoin correctement édité' });
            }
        ).catch(error => {
              this.setState({ showAlert: 'block', alertType: 'alert-warning', alertMessage: 'Problème lors de l\'édition de votre besoin. Réessayez plus tard' });
            }
        )
  }

  handleChange(i, e) {
    this.setState({
      answers: { ...this.state.answers, [i]: { ...this.state.answers[i], answer: e.target.value}
      }
    });
  }

  descriptionChange = (event) => {
    this.setState({
      need: { ...this.state.need, description: event.target.value }
    });
  }

  feedbackChange = (event) => {
    this.setState({
      need: { ...this.state.need, feedback: event.target.value }
    });
  }

  render() {
    return (
      <div className="qualify-form block shadow basic-form">
        <Flash display={this.state.showAlert} type={this.state.alertType} message={this.state.alertMessage} close={this.close} />
          <div className="qualify-form__title">
            <h4>Brief, Questions & Commentaires</h4>
          </div>
          <form onSubmit={this.handleSubmit} className="qualify-form__form">
            <ul className="qualify-form__answer-list">

              <li>
                <label>Description du besoin</label>
                <textarea type="text" value={this.state.need.description} onChange={this.descriptionChange} />
              </li>
              <li>
                <label>Note pour les prestataires (non visible par le client)</label>
                <textarea type="text" value={this.state.need.feedback} onChange={this.feedbackChange} />
              </li>

              {this.props.answers.map((answer, index) => {
                return(
                    <li key={index}>
                      <label>{answer.question}</label>
                      <textarea type="text" value={this.state.answers[index].answer} onChange={this.handleChange.bind(this, index)} />
                    </li>
                  )
              }
              )}
            </ul>
            <button type="submit" className="blue-button">Valider l'avis</button>
            <div className="form-check">
              <input
                ref={'warnProviders'}
                className="form-check-input boolean optional"
                type="checkbox"
                value="1"
                name="warn_providers"
                id="warn_providers"/>
              <label className="form-check-label boolean optional" htmlFor="warn_providers">
                Avertir les prestataires
              </label>
            </div>
            <div className="form-check">
              <input
                  ref={'transferAddress'}
                  className="form-check-input boolean optional"
                  type="checkbox"
                  defaultChecked={this.state.need.transfer_address}
                  name="transfer_address"
                  id="transfer_address"/>
              <label className="form-check-label boolean optional" htmlFor="transfer_address">
                Transférer l'adresse au prestataire
              </label>
            </div>
          </form>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    answers: state.answers,
    need: state.need
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAnswers, postAnswers, requalifyNeed, fetchNeed }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QualifyForm);


