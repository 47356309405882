import React from "react";
import { Modal } from "react-bootstrap";
import { CustomButton } from "../custom_button/custom_button";
import "./change_role_modal.css";

export const ChangeRoleModal = (props) => {
  const { show, membership, handleClose, onValidate } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        id="rolesModal"
        className="change-role-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <p className="changeRoleText">
              {" "}
              Etes-vous certain de vouloir modifier le rôle de
            </p>
            <p className="changeRoleText"> ce collaborateur ?</p>
          </div>
          <div>
            <center>
              <CustomButton
                name="annuler"
                type="button"
                value="Annuler"
                width="100"
                height="48"
                color="#143456"
                backgroundColor="secondary"
                borderRadius="56"
                borderColor="#143456"
                onClick={handleClose}
              />
              <a
                className="modal_change_role_link"
                role="button"
                onClick={onValidate}
              >
                <button className="modal_change_role_btn">Changer</button>
              </a>
            </center>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
