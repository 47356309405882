import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { manageNeed, fetchNeed } from '../../actions/index';
import Flash from '../../components/flash/flash';
import "./management_form.css";

class ManagementForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      need: this.props.need,
      showAlert: 'none',
      alertType: '',
      alertMessage: '',
    };
  }

  close = () =>{
    this.setState({ showAlert: 'none' });
  }

  open = () => {
    this.setState({ showAlert: 'block' });
  }

  componentWillMount() {
    this.props.fetchNeed(this.props.needId);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const transferAddress = document.getElementById('transfer_address').checked
    this.props.manageNeed(this.props.needId, this.state.need.brief, this.state.need.done_at, transferAddress
      ).then(res => {
             this.setState({ showAlert: 'block', alertType: 'alert-info', alertMessage: 'Instructions mises à jour !' });
            }
        ).catch(error => {
              this.setState({ showAlert: 'block', alertType: 'alert-warning', alertMessage: 'Problème lors de l\'édition de votre besoin. Réessayez plus tard' });
            }
        )
  }

  briefChange = (event) => {
    this.setState({
      need: { ...this.state.need, brief: event.target.value }
    });
  }

  dateChange = (event) => {
    this.setState({
      need: { ...this.state.need, done_at: event.target.value }
    });
  }


  render() {
    return (
      <div className="management-form block shadow basic-form">
        <Flash display={this.state.showAlert} type={this.state.alertType} message={this.state.alertMessage} close={this.close} />
          <div className="management-form__title">
            <h4>Management de la prestation</h4>
          </div>
          <form onSubmit={this.handleSubmit} className="management-form__form">
            <ul className="management-form__answer-list">

              <li>
                <label>Brief de mission</label>
                <textarea type="text" defaultValue={this.state.need.brief} onChange={this.briefChange} />
              </li>
              <li>
                <label>Date de prestation</label>
                <input className="form-control string datepicker-here" data-language="fr" autoComplete="off" name="expires_at" value={this.state.need.done_at} onChange={this.dateChange} onBlur={this.dateChange} />
              </li>

            </ul>
            <button type="submit" className="blue-button">Valider</button>
            <div className="form-check">
              <input
                ref={'transferAddress'}
                className="form-check-input boolean optional"
                type="checkbox"
                defaultChecked={this.state.need.transfer_address}
                name="transfer_address"
                id="transfer_address"/>
              <label className="form-check-label boolean optional" htmlFor="transfer_address">
                Transférer l'adresse au prestataire
              </label>
            </div>
          </form>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    need: state.need
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ manageNeed, fetchNeed }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagementForm);


