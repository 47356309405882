import React from "react" /**/;
import { MembreTableRow } from "./members_table_row";

export function MembersTable({
  memberships,
  currentUserMembership,
  currentFirm,
}) {
  const new_member_path = "/members/new";
  return (
    <>
      <div className="members__header">
        <span className="participants-all-header__title">
          Mon équipe “{currentFirm?.name}”
        </span>

        <a className="header_btn_participants_link_btn" href={new_member_path}>
          {" "}
          <span className="header-link-participants__link__btn">
            + &nbsp;Ajouter un membre
          </span>
        </a>
      </div>
      <header className="members-table-row members-table-row--6col members-table-row--header">
        <div className="members-table-row__col">
          <span className="members-table-row__title">Collaborateurs</span>
        </div>
        <div className="members-table-row__col members-table-row__col--start">
          <span className="members-table-row__title">Fonction</span>
        </div>

        <div className="members-table-row__col members-table-row__col--start">
          <span className="members-table-row__title">Email</span>
        </div>

        <div className="members-table-row__col members-table-row__col--center">
          <span className="members-table-row__title">Mobile</span>
        </div>
        <div className="members-table-row__col members-table-row__col--center">
          <span className="members-table-row__title">Tel</span>
        </div>
        <div className="members-table-row__col__end members-table-row__col--center">
          <span className="members-table-row__title">
            <br />
            Reçoit les <br /> nouvelles <br /> demandes
          </span>
        </div>
        <div className="members-table-row__col table-row__col--center">
          <span className="members-table-row__title">Rôle</span>
        </div>
        <div className="members-table-row__col table-row__col--start">
          <span className="members-table-row__title"></span>
        </div>
      </header>
      <div>
        {memberships.map((membership) => (
          <MembreTableRow
            key={membership.id}
            membership={membership}
            currentUserMembership={currentUserMembership}
          />
        ))}
      </div>
    </>
  );
}
