// NB: I tried to make this an ERB file to read ruby ivar & constants
//  but does not seem to work. It would be great to fix this so I can use
//     state constant instead

import NeedsList from "../../containers/needs_list/needs_list";
import "./needs_list.css";

const selector = "#js-dashboard__dashboard-root";
const reactRootDomEl = document.getElementById(selector.substring(1));
const reactRootDomSelectors = {};

if (reactRootDomEl) {
  const showColumns = JSON.parse(reactRootDomEl.dataset.show);

  reactRootDomSelectors[selector] = {
    component: NeedsList,
    props: {
      data: JSON.parse(reactRootDomEl.dataset.data),
      showColumns: showColumns,

      updateMyData: (rowIndex, columnID, value) => {
        // We also turn on the flag to not reset the page
        skipPageResetRef.current = true;
        setData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                [columnID]: value
              };
            }
            return row;
          })
        );
      }
    }
  };
}

export { reactRootDomSelectors };

// id budget budget_wtax cancel_link client contact created_at done_at menu_state message_link provider_names rating renew_link service_name show_link state type urgent warning
// id budget budget_wtax cancel_link client contact created_at done_at menu_state message_link provider_names rating renew_link service_name show_link state type urgent warning
// id budget budget_wtax cancel_link client contact created_at done_at menu_state message_link provider_names rating renew_link service_name show_link state type urgent warning
