import React from "react";
import { Modal } from "react-bootstrap";
import "./partner_modal.css";

export const PartnerModal = (props) => {
  const { show, handleClose } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        id="partnerModal"
        className="partner-modal"
      >
        <Modal.Header closeButton>
          <div>
            <p className="headerText"> Souhaitez-vous devenir Partenaire ?</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="partnerText">
              {" "}
              En tant que client, vous n’avez pas d’accès partenaire. Vous
              pouvez, si vous le souhaitez devenir partenaire et faire profiter
              de vos services les utilisateurs de Riing . 
              <br></br>Discutons en sur <b>concierge@riing.fr</b>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
