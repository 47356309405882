import React, { Component } from "react";
import ChatList from "../../containers/chat_list/chat_list";
import RequalifiedNeed from "../../containers/requalified_need/requalified_need";
import ProposalList from "../../containers/proposal_list/proposal_list";
import "./need_description_view.css";

class NeedDescriptionView extends Component {
  render() {
    const needId = this.props.match.params.needId;
    return (
      <div className="need-description-view">
        <ChatList
          chatId="null"
          needId={needId}
          scope={this.props.scope}
          state={this.props.state}
        />
        {this.props.currentMembership.role !== "worker" && (
          <ProposalList
            needId={needId}
            title="Aucun devis reçu pour l'instant ..."
            message="Vous recevrez très prochainement des devis pour vous permettre de
          choisir le bon prestataire."
          />
        )}
        <RequalifiedNeed needId={needId} scope={this.props.scope} />
      </div>
    );
  }
}

export default NeedDescriptionView;
