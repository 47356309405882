import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CustomButton } from "../custom_button/custom_button";
import "./participants_modal.css";

export const ParticipantsModal = (props) => {
  const { show, user: selectedUser, handleClose } = props;
  const [inputValue, setInputValue] = useState("");

  const handleClick = () => {
    props.validateModal(inputValue);
    handleClose();
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="participants-modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {show && (
            <div>
              <p style={Styles.textParticipants}>
                {" "}
                Confirmez-vous affecter {selectedUser?.user?.first_name}{" "}
                {selectedUser?.user?.last_name} sur
                <br /> cette demande ?
              </p>
              <center>
                <span style={Styles.spanParticipants}>
                  S'il y a une instruction particulière concernant{" "}
                  {selectedUser?.user?.first_name}, <br />
                  vous pouvez l'inscrire ici
                </span>
                <br />
                <br />
                <textarea
                  placeholder="Ex: absente tous les jeudi et disponible le vendredi matin "
                  onChange={handleChange}
                ></textarea>
                <br />
                <br />
                <CustomButton
                  name="valider"
                  type="button"
                  value="Valider"
                  width="100"
                  height="48"
                  color="white"
                  backgroundColor="primary"
                  borderColor="orange"
                  borderRadius="56"
                  onClick={handleClick}
                />
              </center>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const Styles = {
  textParticipants: {
    color: "#143456",
    fontWeight: 600,
    fontSize: "20px",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "35px",
  },
  spanParticipants: {
    color: "#143456",
    fontWeight: "400",
    fontSize: "13px",
    textAlign: "center",
    verticalAlign: "middle",
  },
};
