import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { CustomButton } from "../custom_button/custom_button";
import "./default_responder_modal.css";
import putJSON from "../../ajax/putJSON";

export const DefaultResponderModal = (props) => {
  const { show, membership, handleClose, close } = props;
  const updateDefaultResponder = useCallback(async () => {
    await putJSON(`/members/${membership.id}`, {
      membership: { default_responder: !membership.default_responder },
    })
      .then(() => close())
      .then(() => window.location.reload(true));
  }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        id="rolesModal"
        className="delete-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {!membership?.default_responder ? (
            <div>
              <p className="deleteText">
                Etes-vous certain de vouloir autoriser ce collaborateur
                à recevoir toutes les nouvelles demandes ?
              </p>
            </div>
          ) : (
            <div>
              <p className="deleteText"> Ce collaborateur ne recevra plus les nouvelles demandes générées par votre équipe</p>
            </div>
          )}
          <div>
            <center>
              <CustomButton
                name="annuler"
                type="button"
                value="Annuler"
                width="100"
                height="48"
                color="#143456"
                backgroundColor="secondary"
                borderRadius="56"
                borderColor="#143456"
                onClick={handleClose}
              />
              <a className="modal_delete_link" role="button">
                <button
                  className="modal_delete_btn"
                  onClick={updateDefaultResponder}
                >
                  Valider
                </button>
              </a>
            </center>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
